<template>
  <div class="container-fluid">
    <div class="card card__bulk">
      <div class="card__title d-block d-md-none">
        <span class="fs18 lh28 fw500">
          Bulk Import Orders
        </span>
      </div>
      <div class="card__content">
        <div class="row">
          <div class="col-md-6 pb-sm-down-24 d-none d-md-block">
            <span class="fs18 lh28 fw500">
              Bulk Import Orders
              <i
                class="icon-disclaimer text-gray-400 fs12 ml-4"
                v-b-tooltip.hover.right
                title="Please Download the Template from right for Correct Format"
              ></i>
            </span>
          </div>
          <div class="col-12 col-md-6 text-right">
            <b-button @click="downloadToast('template')" variant="tertiary w-sm-100">
              <i class="icon-download mr-11"></i>
              Download Template
            </b-button>
          </div>
        </div>
        <div class="row">
          <div class="col pt-24">
            <b-form-file
              accept=".xlsx, .xls"
              :browse-text="browseText"
              v-model="files"
              ref="bulkUpload"
              multiple
              placeholder="Drop your template here or click on the button below"
              drop-placeholder="Drop your template here or click on the button below"
              @input="detectFiles()"
              id="bulk-input"
              class="bulk-input"
              :class="{ 'has-file': active }"
              :disabled="submitting"
            >
            </b-form-file>
            <div v-if="submitting" class="loader"><b-spinner></b-spinner></div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <span class="fs16 lh28 fw500 text-gray-600 mt-32 mb-34 mb-md-10 d-block">
              Partial Results
            </span>
            <b-table
              table-class="dark-header new-order"
              :fields="fields"
              :items="items"
              responsive
              ref="table"
              :busy="isBusy"
            >

            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

              <template v-slot:cell(filename)="data">
                <span class="original_file">{{ data.value }}</span>
                <b-button
                  @click="downloadFile(data.item.originalFile, data.item.filename)"
                  variant="tertiary btn-xs ml-12 bg-gray-100 text-gray-800"
                >
                  Download
                </b-button>
              </template>
              <template v-slot:cell(date)="data">
                {{data.value | date}}
              </template>
              <template v-slot:cell(bulked)="data">
                <i class="icon-booked text-green mr-8"></i>
                {{ data.value }} successful orders
                
              </template>
              <template v-slot:cell(error)="data">
                <i class="icon-failed text-red mr-8"></i>
                {{ data.value }} error orders
                <b-button v-if="( data.item.orders == (data.item.bulked + data.item.error) ) && (!Number(data.value) == 0)"
                  @click="downloadFile(data.item.errorFile, data.item.errorFileName)"
                  variant="tertiary btn-xs ml-12 bg-gray-100 text-gray-800"
                >
                  Download
                </b-button>
              </template>
            </b-table>
            <div v-if="noData && !isBusy">
              <div class="no-results">
                <div class="d-flex flex-column align-items-center">
                  <i class="icon-empty-state text-blue pb-24 fs30"></i>
                  <span class="fs14 fw500 pb-8 text-blue">
                    Couldn't find any matching results
                  </span>
                  <span class="fs14 fw500 pb-8 text-gray-600">
                    Please Upload a File!!
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "NewOrderBulk",
  data() {
    return {
      isBusy:false,
      files: null,
      active: false,
      browseText: "Browse File",
      submitting:false,
      items: [
        // {
        //   filename: "July_2020_Shipments.xls",
        //   date: "08/08/2021",
        //   orders: 421,
        //   bulked: 391,
        //   error: 30,
        // },
        // {
        //   filename: "December_2020_Shipments.xls",
        //   date: "12/12/2021",
        //   orders: 1841,
        //   bulked: 1341,
        //   error: 87,
        // },
      ],
      fields: [
        {
          key: "filename",
          label: "File Name",
          sortable: true,
        },
        {
          key: "date",
          sortable: true,
        },
        { key: "orders", label: "No. of orders", sortable: true },
        {
          key: "bulked",
          label: "Successfully Booked",
          sortable: true,
          class: "wsnw",
        },
        {
          key: "error",
          label: "Error",
          sortable: true,
          class: "wsnw",
        },
      ],
      noData:false
    };
  },
  methods: {
    downloadFile(file, filename){
      this.popToast("requested","Download requested","Your download should start now.");
      this.download(file, filename)
    },
    goBack() {
      this.$router.go(-1);
    },
    clearFiles() {
      this.$refs["bulkUpload"].reset();
      this.active = false;
      this.browseText = "Browse";
    },
    async detectFiles() {
      if (this.files[0] != null) {
        let fd = new FormData();
        fd.append("file", this.files[0]);
        this.browseText = 'Uploading...'
        this.submitting = true
        try {
            const res = await this.axios.post('/orders/new', fd)
            if (res.data.error) {
              throw new Error(res.data.error);
            }

            if(res.data.success == false ) {
              this.submitting = false
              this.active = true;
              this.popToast("failed","Failed!",res.data.message || "File Upload failed!! Please Try again later")
              this.browseText = "Browse File";
              this.files = null
            }
            else {
              this.popToast("booked","Success!","File Upload successful!! Please Check below Table for Results");
              this.submitting = false
              this.active = true;
              this.items = []
              this.getBulktable();
              this.files = null
              this.browseText = "Browse File";  
            }

            
          } catch(e) {
            this.submitting = false
            this.active = true;
            this.popToast("failed","Failed!",e.message || "File Upload failed!! Please Try again later")
            this.browseText = "Browse File";
            this.files = null
        }
      } else if (!this.files) {
        this.active = false;
        this.browseText = "Browse";
      }
    },
    downloadToast(type) {
      let samplePath = "https://s3-ap-southeast-1.amazonaws.com/shyplitepublicassets/assets/uploadFormats/bulkorders.xlsx"
      if(type == 'template') {
        this.download(samplePath, "Bulk Order Sample Template.xlsx")
      }
      this.popToast("requested","Download requested","Your download should start now.");
    },
    async getBulktable() {
      this.isBusy = true
      try {
        const res = await this.axios.get("/orders/bulk/detail")
        if (res.data.length) {
          res.data.forEach((item, index) => {
            this.items.push({
              filename:item.file_name,
              originalFile:item.s3_file_path,
              date:item.date,
              orders:item.total_count,
              bulked:item.success_count,
              error: item.error_count,
              errorFileName: item.error_file_name,
              errorFile:item.s3_error_file_path
            })
          })
        this.isBusy = false
        this.noData = false
        } else {
          this.isBusy = false
          this.noData = true
        }
      } catch(e) {
        this.isBusy = false
        this.noData = true
      }
    }
  },
  mounted() {
    this.getBulktable();
  },
  computed: {
    ...mapState(["user"])
  }
};
</script>


<style scoped type="text/css">
.loader {
    position: absolute;
    bottom: 23px;
    z-index: 1;
    width: 100%;
    text-align: center;
}
/deep/ .bulk-input .custom-file-label {
  top:unset !important;
  height: 99% !important;
}


/deep/ .bulk-input .custom-file-label:before {
  margin-top: 2rem !important;
}

/deep/ .custom-file-input ~ .custom-file-label[data-browse]::after {
  margin-top: 1rem !important;
}

/deep/ .dark-header.new-order tbody tr td:first-child {
  width: 40% !important;
}

/deep/ .dark-header.new-order tbody tr td:first-child .original_file {
  word-break: break-word;
}



</style>
