var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-modal", {
        ref: "intlRedirect",
        attrs: {
          centered: "",
          title: "International Order",
          id: "intlRedirect",
          "modal-class": "cancel-modal",
          "no-close-on-backdrop": ""
        },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function(ref) {
              var close = ref.close
              return [
                _c("span", { staticClass: "fs18 lh28 fw500" }, [
                  _vm._v(" International Order ")
                ])
              ]
            }
          },
          {
            key: "modal-footer",
            fn: function(ref) {
              var close = ref.close
              return [
                _c("div", { staticClass: "mb-30" }, [
                  _c("p", [
                    _vm._v(
                      "You will be redirected to Shypmax for international orders, you can use same login and password."
                    )
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between w-100" },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "m-0",
                        attrs: { id: "btnHide", variant: " w-sm-100", to: "/" }
                      },
                      [_c("span", [_vm._v("Cancel")])]
                    ),
                    _c(
                      "a",
                      {
                        staticClass: "m-0 btn btn-primary",
                        attrs: {
                          id: "btnHide",
                          variant: "primary w-sm-100",
                          href: "https://app.shypmax.com/order#Single",
                          title: "Redirect to Shypmax"
                        }
                      },
                      [
                        _c("span", [_vm._v("Proceed")]),
                        _c("i", { staticClass: "icon-arrow fs13 ml-10" })
                      ]
                    )
                  ],
                  1
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.isOpen,
          callback: function($$v) {
            _vm.isOpen = $$v
          },
          expression: "isOpen"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }