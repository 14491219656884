<template>
  <b-form @submit.prevent="formSubmit">
    <div class="container-fluid">
      <div class="card card__new-order mb-16 mb-md-12">
        <div class="row">
          <div class="col-lg-6">
            <div class="card__content">
              <div class="mx-auto">
                <span class="fs18 lh28 fw500">
                  Order Information
                </span>
                <div class="row">
                  <div class="col" :class="{'col-md-6': $store.state.global.showConsignor  && $store.state.global.showConsignorrequest }">
                    <b-form-group
                      id="warehouse-field"
                      label="Warehouse"
                      label-for="warehouse"
                      class="mt-12"
                    >
                      <multiselect
                        id="warehouse"
                        title="Please select an item from the list"
                        v-model="form.sellerAddressId"
                        :options="mpsOrderWarehouses"
                        label="name"
                        ref="warehouse"
                        required
                        :show-labels="false"
                      ></multiselect>
                    </b-form-group>
                  </div>
                  <div class="col-md-6 col" v-if="$store.state.global.showConsignor  && $store.state.global.showConsignorrequest">
                    <b-form-group
                      id="warehouse-field"
                      label="Consignor"
                      label-for="consignor"
                      class="mt-12"
                    >
                      <multiselect
                        id="consignor"
                        title="Please select an item from the list"
                        v-model="form.consignorID"
                        :options="singleorderconsignors"
                        label="name"
                        ref="warehouse"
                        class="select-neworder"
                        :searchable="true"
                        :show-labels="false"
                      ></multiselect>
                    </b-form-group>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <b-form-group
                      id="warehouse-address-field"
                      label="Address"
                      label-for="warehouse-address"
                    >
                      <div class="input-group-addon">
                        <b-form-textarea
                          id="warehouse-address"
                          type="text"
                          v-model="warehouseAddress"
                          :maxlength="this.maxLength"
                          disabled
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </div>
                    </b-form-group>
                  </div>
                </div>

                  <div class="row">
                    <div class="col">
                        <b-form-group
                        id="orderId"
                        label-for="order-id"
                      >
                      <label
                        for="orderId"
                        v-b-tooltip.hover.right
                        title="No Special Characters are Allowed"
                        class="wsnw"
                      >
                        Order ID
                        <i class="icon-disclaimer fs12 text-gray-400"></i>
                      </label>
                      <b-form-input
                        id="order-id"
                        v-model="form.orderId"
                        pattern="[a-zA-Z0-9]+"
                        minlength="2"
                        maxlength="35"
                        title="No Special Characters are Allowed"
                        required
                        placeholder="Enter order id"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 col-12">
                    <b-form-group id="date-field" label="Date" label-for="date">
                      <date-picker
                        prefix-class="xmx"
                        v-model="form.orderDate"
                        required
                        ref="orderDate"
                        id="date"
                        placeholder="yyyy-mm-dd"
                      >
                        <i slot="icon-calendar" class="icon-calendar"></i>
                      </date-picker>
                    </b-form-group>
                  </div>

                  <div class="col-md-6 col-12">
                    <b-form-group id="type-field" label="Order Type" label-for="type">
                      <multiselect
                        id="type"
                        value="Prepaid"
                        :options="types"
                        readonly="true"
                        :searchable="false"
                        :show-labels="false"
                      ></multiselect>
                    </b-form-group>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 col-12">
                    <b-form-group
                      id="total-value-field"
                      label="Total Value"
                      label-for="total-value"
                    >
                      <b-input-group append="₹">
                        <b-input
                          v-model="form.totalValue"
                          @input="totalValueEnable"
                          id="total-value"
                          type="number"
                          placeholder="0.00"
                          step=".01"
                          min="1"
                          required
                        ></b-input>
                      </b-input-group>
                    </b-form-group>
                  </div>
                  <div class="col-md-6 col-12" v-if = "$store.getters.user.choice == 0">
                    <b-form-group id="mode-field" label="Mode Type" label-for="mode">
                      <multiselect
                        id="mode"
                        v-model="form.modeType"
                        :options="$store.getters.mode.b2b"
                        required
                        label="name"
                        ref="orderType"
                        :searchable="false"
                        :show-labels="false"
                      ></multiselect>
                    </b-form-group>
                  </div>

                  <div class="col-6" v-if="form.totalValue >= 50000">
                    <b-form-group
                      id="length-field"
                      label="E-WayBill"
                      label-for="ewaybill"
                      class="wsnw"
                    >
                      <b-input
                        id="ewaybill"
                        v-model="form.ewaybill"
                        type="text"
                        placeholder="E-WayBill"
                        required
                      ></b-input>
                    </b-form-group>
                  </div>
                                    
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="card__content">
              <div class="mx-auto">
                <span class="fs18 lh28 fw500">
                  Customer Information
                </span>
                <div class="row">
                  <div class="col-12 col-md-6">
                    <b-form-group
                      id="customer-name-field"
                      label="Name"
                      label-for="customer-name"
                      class="mt-12"
                    >
                      <b-form-input
                        id="customer-name"
                        v-model="form.customerName"
                        required
                        maxlength="35"
                        placeholder="Enter customer name"
                        @keyup="getCustomers(form.customerName)"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="results-wrap" v-if="customerList && customerList.length">
                    <ul class="search-results" :style=" customerList && customerList.length == 1 ?  'height: max-content !important' : '' ">
                      <li v-for="item in customerList" class="p-5 d-flex" :key="item.id">
                        <div class="w-100 col d-flex" @click="fillCustomerfield(item.id)">
                            <div >
                              <p ><span><strong>Name</strong> - </span>{{ item.name }}</p>
                              <p ><span><strong>Pincode</strong> - </span>{{ item.pincode }}</p>
                              <p ><span><strong>Contact</strong> - </span>{{ item.contact }}</p>
                            </div>
                          </div>
                      </li>
                    </ul>
                  </div>
 
                    <div class="col-12 col-md-6">
                        <b-form-group 
                            label="Email (Optional)" 
                            label-for="customer-email" 
                            class="mt-12"
                        >
                            <b-form-input
                              id="customer-email"
                              v-model="form.email"
                              placeholder="Customer Email"
                              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                              title="Olny valid email id is allowed."
                              type="email"
                            ></b-form-input>
                        </b-form-group>
                    </div>
                </div>

                <div class="row">
                  <div class="col">
                    <b-form-group
                      id="customer-address-field"
                      label="Address"
                      label-for="customer-address"
                      :description="
                        'Characters: ' +
                          form.customerAddress.length +
                          ' / ' +
                          this.maxLength
                      "
                    >
                      <div class="input-group-addon">
                        <b-form-textarea
                          id="customer-address"
                          type="text"
                          v-model="form.customerAddress"
                          required
                          placeholder="Enter customer address"
                          :maxlength="this.maxLength"
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </div>
                    </b-form-group>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <b-form-group id="city-field" label="City" label-for="city">
                      <b-form-input
                        id="city"
                        v-model="form.customerCity"
                        required
                        placeholder="Enter city"
                        maxlength="35"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group id="pin-field" label="Pin Code" label-for="pin">
                      <b-form-input
                        id="pin"
                        v-model="form.customerPinCode"
                        required
                        type="text"
                        placeholder="110001"
                        maxlength="6"
                        minlength="6"
                        oninvalid="this.setCustomValidity('Enter valid pincode.')"
                        oninput="this.setCustomValidity('')"
                        :formatter="numbers"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 col-12">
                    <b-form-group
                      id="phone-field"
                      label="Mobile number"
                      label-for="phone-number"
                    >
                      <b-input-group>
                        <b-form-input
                          id="phone-number"
                          v-model="form.customerContact"
                          required
                          type="text"
                          maxlength="10"
                          minlength="10"
                          :formatter="numbers"
                          placeholder="8888888888"
                          oninvalid="this.setCustomValidity('Enter valid mobile number.')"
                          oninput="this.setCustomValidity('')"
                        ></b-form-input>
                        <div class="input-group-append">
                          <div class="input-group-text">
                            <i class="icon-phone fs16 text-gray-600"></i>
                          </div>
                        </div>
                      </b-input-group>
                    </b-form-group>
                  </div>
                  <div class="col-md-6 col-12">
                    <b-form-group id="city-field" label="GSTIN" label-for="gstin">
                      <b-form-input
                        id="gstin"
                        v-model="form.customerGST"
                        required
                        placeholder="Enter company’s GSTIN"
                        maxlength="15"
                        pattern="[0-9a-zA-Z]*"
                        title="Only alphanumeric characters allowed."
                      ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>

        <div class="border-top w-100 mt-16 mb-16"></div>

          <div class="row">
            <div class="col-lg-12">
              <div class="card__content">
                  <span class="d-block fs18 lh28 fw500 mb-14">
                      Package Information
                  </span>
                  <div class="row">
                    <div class="col-12 col-md-3">
                      <b-form-group
                        id="package-field"
                        label="Package Name"
                        label-for="package-name"
                        class="mt-12"
                      >
                        <b-form-input
                          id="package-name"
                          v-model="form.packageName"
                          required
                          maxlength="100"
                          placeholder="Enter package name"
                          type="text"
                        ></b-form-input>
                      </b-form-group>
                    </div>
                  </div>

                  <ul class="package-items">
                      <li v-for="(item, index) in form.skuList" :key="index" class="p-0">
                          <div class="row">
                              <div class="col-md-2 col-6">
                                <b-form-group :label="index > 0 ? '' : 'Identical Carton'">
                                      <b-input
                                          v-model="item.identical"
                                          required
                                          min="1"
                                          type="number"
                                          placeholder="0"
                                          @input="totalIdentical"
                                          title="Only numeric value allowed."
                                      ></b-input>
                                </b-form-group>
                              </div>

                              <div class="col-md-2 col-6">
                                  <b-form-group :label="index > 0 ? '' : 'Item Qty / Carton' ">
                                      <b-input
                                          v-model="item.quantity"
                                          required
                                          placeholder="0"
                                          :min="1"
                                          type="number"
                                          @input="totalQtyCarton"
                                          title="Only numeric value allowed."
                                      ></b-input>
                                  </b-form-group>
                              </div>

                              <div class="col-md-2 col-6">
                                  <b-form-group :label="index > 0 ? '' : 'Weight (kg) / Carton'">
                                      <b-input
                                          v-model="item.packageWeight"
                                          required
                                          step=".01"
                                          :min="$store.state.minW"
                                          :max="$store.state.maxW"
                                          placeholder="0.00"
                                          type="number"
                                          @input="totalWeight"
                                          title="Only numeric value allowed."
                                      ></b-input>
                                  </b-form-group>
                              </div>

                              <div class="col-md-4">
                                  <div class="row justify-content-center">
                                      <div class="col-md-1"></div>
                                      <div class="col-md-3 col-6">
                                          <b-form-group :label="index > 0 ? '' : 'Length (cm)'">
                                              <b-input
                                              v-model="item.packageLength"
                                              required
                                              title="Only numeric value allowed."
                                              placeholder="0"
                                              max="999"
                                              min="1"
                                              type="number"
                                              ></b-input>
                                          </b-form-group>
                                      </div>

                                      <div class="col-md-3 col-6">
                                          <b-form-group :label="index > 0 ? '' : 'Width (cm)'">
                                              <b-input
                                              v-model="item.packageWidth"
                                              title="Only numeric value allowed."
                                              required
                                              placeholder="0"
                                              max="999"
                                              min="1"
                                              type="number"
                                              ></b-input>
                                          </b-form-group>
                                      </div>

                                      <div class="col-md-3 col-6">
                                          <b-form-group :label="index > 0 ? '' : 'Height (cm)'">
                                              <b-input
                                              v-model="item.packageHeight"
                                              title="Only numeric value allowed."
                                              max="999"
                                              min="1" 
                                              required
                                              placeholder="0"
                                              type="number"
                                              ></b-input>
                                          </b-form-group>
                                      </div>
                                      <div class="col-md-1"></div>
                                  </div>
                              </div>

                              <div class="col-md-2 col-12">
                                  <div class="row">
                                      <div class="col-md-10 col-10">
                                         <b-form-group :label="index > 0 ? '' : 'Value (₹)'">
                                              <b-input
                                                  v-model="item.value"
                                                  required
                                                  @input="totalValueEnable"
                                                  placeholder="0.00"
                                                  title="Only numeric value allowed."
                                                  type="number"
                                                  pattern="((d+)((.d{1,2})?))$"
                                                  min="1"
                                                  required
                                                  step=".01"
                                              ></b-input>
                                          </b-form-group> 
                                      </div>
                                      <div class="col-md-2 col-2 align-items-center ">
                                         <b-link
                                              @click="form.skuList.splice(index, 1);
                                              totalValueEnable();
                                              totalQtyCarton();
                                              totalWeight();
                                              totalIdentical()"
                                              class="btn-trash mt-16"
                                              v-b-tooltip.hover.right
                                              v-if="index>0"
                                              title="Delete item"
                                         >
                                         <i class="icon-trash"></i>
                                      </b-link>
                                 </div>
                              </div>
                          </div>
                          </div>
                      </li>
                  </ul>

                    <div class="row">
                        <div class="col-2">
                            <div v-if="totalIdenticalBox > 0" class="float-left cm mr-12">Total Identical Carton: {{ totalIdenticalBox }}</div>
                        </div>
                        <div class="col-2">
                            <div v-if="totalQty > 0" class="float-left cm mr-12">Total Item Qty: {{ totalQty }}</div>
                        </div>                        
                        <div class="col-2">
                            <div v-if="rowTotalWeight > 0" class="float-left cm mr-12">Total Weight: {{ rowTotalWeight }}</div>
                        </div>                        
                        <div class="col-2">
                        </div>                        
                        <div class="col-2">
                        </div>                        
                        <div class="col-2">
                            <div v-if="totalIdenticalBoxValue > 0" class="float-left cm mr-12">Total Value: {{ totalIdenticalBoxValue }}</div>
                        </div>
                    </div>

              </div>

            </div>

            <choiceDialog @openModal="openPricingModal" :formInfo="formInfo" :choiceData="choiceData" ref="choiceDialog"/>

          </div>

          <div class="row">
              <div class="col">
                  <b-button
                      @click="form.skuList.push({
                        identical: '',
                        quantity: '',
                        packageWeight: '',
                        packageLength: '',
                        packageWidth: '',
                        packageHeight: '',
                        value: '',
                      })"
                      variant="tertiary"
                      class="mt-20 mb-48 ml-0 ml-md-14 w-sm-100"
                  >
                      <i class="icon-add fs16 mr-10"></i>
                      Add Carton
                  </b-button>
              </div>
          </div>

        <div class="row d-flex flex-column">
            <span class="float-right text-danger ml-16" v-if="submitDisable == true">Total Value not matched*</span>
            <div class="d-flex my-16 md-24 ml-16">
                <b-button
                    variant="primary"
                    type="submit"
                    size="lg"
                    :disabled="submitDisable"
                    class="w-sm-100"
                >
                    Create Order <b-spinner v-if="spinnerb2b" class="ml-10"></b-spinner>
                </b-button>
            </div>
        </div>
    </div>

      <div class="card mb-20">
        <div class="card__content">
          <span class="fs18 lh24 fw500">
            Please Note:
          </span>
          <ul class="info-card mt-20">
            <li>
              <span>
                  Kindly declare correct weight and dimensions of each carton.
              </span>
            </li>
            <li>
              <span>
                  In case of non-identical cartons wrong declaration can lead to pickup issues.
              </span>
            </li>
            <li>
              <span>
                  Any B2B shipment shall not be eligible for weight dispute.
              </span>
            </li>
          </ul>
        </div>
      </div>
      </div>

    </div>
  </b-form>
</template>

<script>
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import NumberInput from "@/components/NumberInput.vue";
import choiceDialog from "@/components/choiceDialog.vue";

export default {

  name: "NewOrderB2B",

  data() {

    return {
        totalQty: '',
        rowTotalWeight: '',
        totalIdenticalBox: '',
        totalIdenticalBoxValue: '',
        totalValue: null,
        email: null,
        inputStep: 1,
        customerList:[],
        spinnerb2b:false,
        orderId: null,
        date: null,
        pincode:false,
        value: null,
        allSelected: false,
        maxLength: "105",
        warehouseAddress: "",
        customerAddress: "",
        identical: "identical",
        options: [],
        mpsOrderWarehouses: [],
        sellerAddressId: null,
        submitDisable: false,
        formInfo: [],
        choiceData: [],
        types: ["PREPAID"],
        singleOrderPackages: [],
        form: {
                customerAddress: '',
                orderDate: new Date(),
                // identical: [],
                // quantity: [],
                // packageWeight: [],
                // packageLength: [],
                // packageWidth: [],
                // packageHeight: [],
                // value: [],
                skuList:[{
                  identical: '',
                  quantity: '',
                  packageWeight: '',
                  packageLength: '',
                  packageWidth: '',
                  packageHeight: '',
                  value: '',
              }],
        },
        items: [
            {
              id: 1,
              quantity: "",
              weight: "",
              dimensions: { width: "", height: "", length: "" },
              value: "",
            },
        ],
        identicalFields: [
            {
              key: "identical",
              label: "Identical Boxes",
            },
            {
              key: "quantity",
              label: "Quantity / Box",
            },
            {
              key: "weight",
              label: "Weight(kg) / Carton",
            },
            {
              key: "dimensions",
            },
            {
              key: "value",
              label: "Value / Carton",
            },
            {
              key: "add_button",
              label: "",
            },
        ],
    };
  },
    components: {
        DatePicker,
        Multiselect,
        NumberInput,
        choiceDialog,
    },
    mounted() {
        this.choiceB2BOrder();
        const customerobj = Object.assign({},this.$store.state.customerObj)
        if (customerobj && customerobj.selected && customerobj.selected.name == 'B2B') {
          let obj = {
            customerName:customerobj.name,
            email:customerobj.email,
            customerContact:customerobj.contact,
            customerPinCode:customerobj.pincode,
            customerCity:customerobj.city,
            customerAddress: customerobj.address,
            customerGST:customerobj.gst ? customerobj.gst : '',
            orderDate: new Date(),
            skuList:[{
                  identical: '',
                  quantity: '',
                  packageWeight: '',
                  packageLength: '',
                  packageWidth: '',
                  packageHeight: '',
                  value: '',
              }]
          }

          if (obj.email == null) {
            delete obj.email
          } 

          this.form = obj
          this.$store.commit('setCustomerObj',{})
        }
        
    },
    methods: {

        fillCustomerfield(id) {

            let cust = this.customerList.filter(i => i.id == id)

            try {
                this.form.customerAddress = cust[0].address ? cust[0].address : ''
                this.form.customerName = cust[0].name ? cust[0].name : ''
                this.form.customerPinCode = cust[0].pincode ? cust[0].pincode : ''
                this.form.customerCity = cust[0].city ? cust[0].city : ''
                this.form.customerContact = cust[0].contact ? cust[0].contact : ''
                this.form.email = cust[0].email ? cust[0].email : ''
                this.form.customerGST  = cust[0].gst ? cust[0].gst : ''
                this.customerList = null
            } catch(e) {
              // statements
              console.error(e);
            }
            
          },

          async getCustomers(customerName) {

            this.customerList = {}

            if (customerName && customerName.length > 3) {

              try {
                const res = await this.axios.get(`/v4/customerAddressBook?page=1&offset=25&name=${customerName}`)
                if (res.data.response.success) {
                  this.customerList = Object.assign([],res.data.response.data)
                } else {}

              } catch(e) {
                // statements
                console.log(e);
              }
            }
            
          },

        totalValueEnable() {
            
            let declearTotal = this.form.totalValue

            let totalValue = 0;

            this.form.skuList.map(item => {
              totalValue += item.value ? parseFloat(item.value) : 0
            })

            this.totalValue = totalValue
            this.totalIdenticalBoxValue = totalValue

            if(declearTotal != undefined && totalValue != 0){
                if(declearTotal == totalValue ) {
                    this.submitDisable = false;
                }
                else {
                    this.submitDisable = true;
                }
            }
        
        },

        totalIdentical() {

            let identical = 0;

            this.form.skuList.map(item => {
              identical += item.identical ? parseFloat(item.identical) : 0
            })

            this.totalIdenticalBox = identical;

            this.totalQtyCarton();
        },

        totalQtyCarton() {

            let values = this.form.skuList.map((i,idx) => {

                return parseFloat(i.identical)*parseFloat(i.quantity)

            }).map(Number).filter(Boolean);

            const total = values.reduce((a, b) => a + b, 0);

            this.totalQty = total;

            this.totalWeight();
        },

        totalWeight() {

            let values = this.form.skuList.map((i,idx) => {

                return parseFloat(i.identical)*parseFloat(i.packageWeight)

            }).map(Number).filter(Boolean);

            const total = values.reduce((a, b) => a + b, 0);

            this.rowTotalWeight = total;
        },

        async choiceB2BOrder() {

            try {

                const result = await this.axios.get("/choice/b2b");

                let singleOrderData = result.data;

                this.mpsOrderWarehouses = singleOrderData.address.map((i) => {
                    return {
                        name: i.name, 
                        sellerAddressId: i.id,
                        address: i.address,
                        originCity: i.city,
                        originPin: i.pincode,
                        $isDisabled: i.active == 1 && i.approved == 1 ? false: true,
                    };
                })

                this.singleOrderPackages = singleOrderData.categories.map((i) => {
                    return {
                        name: i,
                    };
                });

                this.singleOrderPackages.splice(18);

            }
            catch(error){
                  console.error(error);
            }

        },

        async formSubmit() {

            if(this.submitDisable == true) {
                this.popToast("failed", "Failed!", 'Please match the total value.');
                return false;
            }

            let formData = Object.assign({}, this.form);

            // formData.identical = []
            // formData.quantity = []
            // formData.packageWeight = []
            // formData.packageLength = []
            // formData.packageWidth = []
            // formData.packageHeight = []
            // formData.value = []


            // for(let i = 0; i < this.items.length; i++){
            //     formData.quantity.push(this.items[i].quantity);
            //     formData.packageWeight.push(this.items[i].packageWeight);
            //     formData.packageLength.push(this.items[i].packageLength);
            //     formData.packageWidth.push(this.items[i].packageWidth);
            //     formData.packageHeight.push(this.items[i].packageHeight);
            //     formData.value.push(this.items[i].value);
            //     formData.identical.push(this.items[i].identical);
            // }

            if (!formData.sellerAddressId) {
                this.$refs.warehouse.$el.focus()
                return false;
            }

            if (!formData.orderDate) {
                this.$refs.orderDate.$el.querySelector("input").focus();
                return false;
            }

            formData.originCity = formData.sellerAddressId.originCity;
            formData.originPin = formData.sellerAddressId.originPin;
            formData.orderType = "prepaid";
            formData.sellerAddressId = formData.sellerAddressId.sellerAddressId;
            formData.orderDate = new Date(formData.orderDate).toLocaleDateString(['en-US'], {year: "numeric",month: "2-digit",day: "2-digit"});

            formData.channel = 37
            if (formData.consignorID && formData.consignorID != null) {
              formData.consignorID = formData.consignorID.id
            } else {
              if (formData.hasOwnProperty('consignorID')) {
                delete formData.consignorID
              }
            }

            try {

                this.spinnerb2b = true

                let result = await this.axios.post("/choice/getchoicedatab2b", formData);

                formData.identical = this.totalIdenticalBox;
                formData.packageWeight = this.rowTotalWeight;

                this.formInfo = formData;

                if (result.data.response){                    
                    this.choiceData = result.data.response.response[0];
                    this.openPricingModal();
                }

                this.spinnerb2b = false

            }
            catch(error){
                this.spinnerb2b = false
                console.error(error);
            }

        },

        openPricingModal: function() {
            this.$refs.choiceDialog.isOpen = !this.$refs
            .choiceDialog.isOpen;
        },

        goBack() {
            this.$router.go(-1);
        },

        addItem() {
            let newItem = {

                quantity: this.items.quantity,
                weight: this.items.weight,
                width: this.items.width,
                height: this.items.height,
                length: this.items.length,
                value: this.items.value,
            };

            this.items.push(newItem);
        },
            removeItem: function(item) {
              const index = this.items.indexOf(item)
              this.items.splice(index, 1);
              this.totalValueEnable();
            },

    },
    computed:{
      singleorderconsignors() {
        return this.$store.state.consignors
      }
    },
    watch: {
      'form.sellerAddressId': function(newValue) {
        if(newValue) {
          this.warehouseAddress = this.form.sellerAddressId.address
        }else {
          this.warehouseAddress = ''
        }
      }
    }
};
</script>

<style lang="scss" scoped>
.cm {
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
}
.search-results {
    position: absolute;
    margin: 0;
    padding: 0;
    top: 6.875rem;
    left: 0.80rem;
    width: calc(100% - 20.6rem);
    height: 13.1875rem;
    overflow-y: scroll;
    list-style-type: none;
    border-radius: 0.5rem;
    background-color: #fff;
    z-index: 1;
      .results {
        font-size: rem(10px);
        line-height: rem(12px);
        color: $gray-600;
        font-weight: 700;
      }
      li {
        cursor:pointer;
        font-size: rem(13px);
        line-height: rem(20px);
        border-top: 1px solid $gray-100;
        margin: 0 rem(5px);

        .result-item {
          position: relative;
          display: inline-flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          text-decoration: none;
          padding: rem(20px) 0 rem(21px) 0;
          .title {
            font-size: rem(13px);
            line-height: rem(20px);
            color: $gray-900;
            margin-right: rem(12px);
          }
          .status {
            font-size: rem(11px);
            line-height: rem(20px);
            color: $gray-600;
            margin-right: rem(12px);
          }
          .meta {
            font-size: rem(11px);
            line-height: rem(20px);
            color: $gray-600;
          }
          i {
            color: $gray-600;
            @include transition;
          }

          &:hover,
          &:focus,
          &:active {
            i {
              color: $blue;
            }
          }
          &:before {
            display: inline-flex;
            content: "";
            width: rem(4px);
            height: rem(4px);
            margin-left: rem(12px);
            margin-right: rem(8px);
            background-color: $blue;
            border-radius: 50%;
          }

          @include media-breakpoint-down(lg) {
            flex-direction: column;
            align-items: flex-start;
            padding: 1rem;
            i {
              position: absolute;
              top: calc(50% - 6px);
              right: rem(8px);
            }
            &:before {
              margin-left: 2px;
              margin-bottom: rem(12px);
            }
          }
        }

        .remove-query {
          color: $gray-600;
          display: inline;
          margin-left: auto;
          text-decoration: none;
          i {
            vertical-align: middle;
          }
          &:hover,
          &:focus,
          &:active {
            color: $red;
          }
        }
      }
    }
</style>