<template>
  <b-form @submit.prevent="formSubmit">
  <div class="container-fluid pb-80 pb-md-0">
    <div class="card card__new-order mb-16 mb-md-12">
      <div class="row">
        <div class="col-lg-6">
          <div class="card__content">
            <div class="mx-auto">
              <span class="d-block fs18 lh28 fw500 mb-14">
                Order Information
              </span>

              <div class="row">
                <div class="col" :class="{'col-md-6': $store.state.global.showConsignor && $store.state.global.showConsignorrequest }">
                  <b-form-group
                    id="warehouse-field"
                    label="Warehouse"
                    label-for="warehouse"
                  >
                    <multiselect
                      id="warehouse"
                      title="Please select an item from the list"
                      v-model="form.sellerAddressId"
                      :options="singleOrderWarehouses"
                      label="name"
                      ref="warehouse"
                      class="select-neworder"
                      :searchable="true"
                      :show-labels="false"
                      @open="toggleLock"
                      @close="toggleLock"
                      required
                    ></multiselect>
                  </b-form-group>
                </div>
                <div class="col-md-6 col" v-if="$store.state.global.showConsignor  && $store.state.global.showConsignorrequest">
                  <b-form-group
                    id="warehouse-field"
                    label="Consignor"
                    label-for="consignor"
                  >
                    <multiselect
                      id="consignor"
                      title="Please select an item from the list"
                      v-model="form.consignorID"
                      :options="singleorderconsignors"
                      label="name"
                      ref="warehouse"
                      class="select-neworder"
                      :searchable="true"
                      :show-labels="false"
                      @open="toggleLock"
                      @close="toggleLock"
                    ></multiselect>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <b-form-group
                    id="warehouse-address-field"
                    label="Address"
                    label-for="warehouse-address"
                  >
                    <div class="input-group-addon">
                      <b-form-textarea
                        id="warehouse-address"
                        type="text"
                        v-model="warehouseAddress"
                        :maxlength="this.maxLength"
                        disabled
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                    </div>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <b-form-group
                    id="orderId"
                    label-for="order-id"
                  >
                  <label
                    for="orderId"
                    v-b-tooltip.hover.right
                    title="Allowed special characters are # - / _"
                    class="wsnw"
                  >
                    Order ID
                    <i class="icon-disclaimer fs12 text-gray-400"></i>
                  </label>
                    <b-form-input
                      id="order-id"
                      placeholder="Enter order id"
                      v-model="form.orderId"
                      minlength="2"
                      maxlength="35"
                      pattern="[0-9a-z#A-Z_ /-]*"
                      title="Allowed special characters are # - / _"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>

            <div class="row">
                <div class="col-md-6">
                    <b-form-group id="date-field" label="Date" label-for="date">
                        <date-picker
                            v-model="form.orderDate"
                            ref="orderDate"
                            id="new-order-daterange"
                            placeholder="yyyy-mm-dd"
                            type="date"
                            :disabled-date="(date) => date > new Date()"
                            prefix-class="xmx"
                            required
                        >
                            <i slot="icon-calendar" class="icon-calendar"></i>
                        </date-picker>
                    </b-form-group>
                </div>

                <div class="col-md-6">
                    <b-form-group id="type-field" label="Order Type" label-for="type">
                        <multiselect
                            id="type"
                            title="Please select an item from the list"
                            v-model="form.orderType"
                            ref="orderType"
                            class="select-neworder"
                            :options="types"
                            :searchable="false"
                            :show-labels="false"
                            @open="toggleLock"
                            @close="toggleLock"
                            required
                        ></multiselect>
                    </b-form-group>
                </div>
            </div>



              <div class="row">
                <div class="col-md-6">
                  <b-form-group
                    id="total-value-field"
                    label="Total Value"
                    label-for="total-value"
                  >
                    <b-input-group append="₹">
                      <b-input
                        id="total-value"
                        v-model="form.totalValue"
                        title="Only numeric or decimal values allowed"
                        type="number"
                        pattern="((d+)((.d{1,2})?))$"
                        step=".01"
                        placeholder="0.00"
                        min="0.1"
                        required
                      ></b-input>
                    </b-input-group>
                  </b-form-group>
                </div>

                <div class="col-md-6" v-if = "$store.getters.user.choice == 0">
                  <b-form-group id="mode-field" label="Mode Type" label-for="mode">
                    <multiselect
                      id="mode"
                      title="Please select an item from the list"
                      v-model="form.modeType"
                      label="name"
                      ref="modeType"
                      class="select-neworder"
                      :options="$store.getters.mode.domestic"
                      :searchable="false"
                      :show-labels="false"
                      @open="toggleLock"
                      @close="toggleLock"
                      required
                    ></multiselect>
                  </b-form-group>
                </div>

                <div class="col-6" v-if="form.totalValue >= 50000">
                  <b-form-group
                    id="length-field"
                    label="E-WayBill"
                    label-for="ewaybill"
                    class="wsnw"
                  >
                    <b-input
                      id="ewaybill"
                      v-model="form.ewaybill"
                      type="text"
                      placeholder="E-WayBill"
                      required
                    ></b-input>
                  </b-form-group>
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card__content">
            <div class="mx-auto">
              <span class="d-block fs18 lh28 fw500 mb-14">
                Customer Information
              </span>
              <div class="row">
                <div class="col-12 col-md-6">
                  <b-form-group
                    id="customer-name-field"
                    label="Name"
                    label-for="customer-name"
                  >
                    <b-form-input
                      id="customer-name"
                      type="text"
                      v-model="form.customerName"
                      maxlength="35"
                      placeholder="Enter customer name"
                      required
                      @keyup="getCustomers(form.customerName)"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="results-wrap" v-if="customerList && customerList.length">
                  <ul class="search-results" :style=" customerList && customerList.length == 1 ?  'height: max-content !important' : '' ">
                    <li v-for="item in customerList" class="p-5 d-flex" :key="item.id">
                      <div class="w-100 col d-flex" @click="fillCustomerfield(item.id)">
                          <div >
                            <p ><span><strong>Name</strong> - </span>{{ item.name }}</p>
                            <p ><span><strong>Pincode</strong> - </span>{{ item.pincode }}</p>
                            <p ><span><strong>Contact</strong> - </span>{{ item.contact }}</p>
                          </div>
                        </div>
                    </li>
                  </ul>
                </div>

                <div class="col-12 col-md-6">
                  <b-form-group label="Email (Optional)" label-for="customer-email">
                    <b-form-input
                    id="customer-email"
                    v-model="form.email"
                    placeholder="Customer Email"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    title="Only valid email id is allowed."
                    type="email"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <b-form-group
                    id="customer-address-field"
                    label="Address"
                    label-for="customer-address"
                    :description="
                      'Characters: ' +
                        form.customerAddress.length +
                        ' / ' +
                        this.maxLength
                    "
                  >
                    <div class="input-group-addon">
                      <b-form-textarea
                        id="customer-address"
                        type="text"
                        v-model="form.customerAddress"
                        placeholder="Enter customer address"
                        rows="3"
                        max-rows="6"
                        maxlength="140"
                        required
                      ></b-form-textarea>
                    </div>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <b-form-group id="city-field" label="City" label-for="city">
                    <b-form-input
                      id="city"
                      v-model="form.customerCity"
                      type="text"
                      placeholder="Enter city"
                      maxlength="35"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-md-6">
                  <b-form-group id="pin-field" label="Pin Code" label-for="pin">
                    <b-form-input
                      id="pin"
                      v-model="form.customerPinCode"
                      placeholder="110001"
                      type="text"
                      maxlength="6"
                      minlength="6"
                      oninvalid="this.setCustomValidity('Enter valid pincode.')"
                      oninput="this.setCustomValidity('')"
                      :formatter="numbers"
                      required
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-md-6">
                  <b-form-group
                    id="phone-field"
                    label="Mobile number"
                    label-for="phone-number"
                  >
                    <b-input-group>
                      <b-form-input
                        v-model="form.customerContact"
                        id="phone-number"
                        type="text"
                        maxlength="10"
                        minlength="10"
                        :formatter="numbers"
                        placeholder="8888888888"
                        oninvalid="this.setCustomValidity('Enter valid mobile number.')"
                        oninput="this.setCustomValidity('')"
                        required
                      ></b-form-input>
                      <div class="input-group-append">
                        <div class="input-group-text">
                          <i class="icon-phone fs16 text-gray-600"></i>
                        </div>
                      </div>
                    </b-input-group>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="border-top w-100 mt-16 mb-16"></div>
        <div class="row">
            <div class="col">
                <span class="d-block fs18 lh28 fw500 mb-5 pl-14">
                    Item Information
                </span>
                <span v-if="user.invoicing && showGstInvoice && form.orderType != 'REVERSE'" class="d-block lh28 fw500 pl-14">
                  Do you want GST Compliant invoice along with label?
                  <b-form-checkbox unchecked-value="0" value="1" v-model="form.isTaxInvoice" class="d-inline mr-20 ml-20 h2" switch></b-form-checkbox>
                </span>

                <ul class="package-items mt-30">
                    <li v-for="(item, index) in form.skuList" :key="index" class="pt-0 pb-0">
                        <div class="row">
                          <div class="col-md-3 col-12">
                            <div class="row">
                              <div class="col-1 col-md-1 d-flex" :class="{'align-items-center': index < 1 }">
                                <span class="fs16 lh19 fw500 mt-16">
                                  #{{ index + 1 }}
                                </span>
                              </div>
                              <div class="col-11 col-md-11">
                                <b-form-group :label="index > 0 ? '' : 'Item Name*'">
                                  <b-form-input
                                  placeholder="Enter item name"
                                  type="text"
                                  maxlength="100"
                                  v-model="item.itemName"
                                  title="Item Name"
                                  required
                                  ></b-form-input>
                                </b-form-group>
                              </div>
                            </div>

                          </div>

                            <div class="col-md-2 col-12">

                                <b-form-group :label="index > 0 ? '' : 'SKU Name'">
                                    <b-form-input
                                        placeholder="SKU Name"
                                        type="text"
                                        maxlength="35"
                                        v-model="item.sku"
                                        title="SKU Name"
                                    ></b-form-input>
                                </b-form-group>
                            </div>

                            <div :class="form.isTaxInvoice=='1' ? 'col-md-1' : 'col-md-2'"  class=" col-6">
                                <b-form-group :label="index > 0 ? '' : 'Quantity*'">
                                    <b-input
                                        min="1"
                                        type="number"
                                        title="Only numeric value allowed."
                                        v-model="item.quantity"
                                        class="new-order-number"
                                        placeholder="0"
                                        required
                                    ></b-input>
                                </b-form-group>
                            </div>

                            <div :class="form.isTaxInvoice=='1' ? 'col-md-1' : 'col-md-2'"  class=" col-6">
                                <b-form-group :label="index > 0 ? '' : 'Weight (kg)'">
                                    <b-input 
                                        @input="totalWeight" 
                                        v-model="item.weight"
                                        type="number" 
                                        title="Only numeric value allowed."
                                        placeholder="0.00"
                                        step=".01"
                                        :min="$store.state.minW"
                                        max="100"
                                    ></b-input>
                                </b-form-group>
                            </div>

                            <div :class="form.isTaxInvoice=='1' ? 'col-md-1' : 'col-md-2'"  class=" col-6">
                              <b-form-group :label="index > 0 ? '' : 'Value'">
                                  <b-input-group append="₹">
                                      <b-input 
                                          type="number" 
                                          :placeholder="form.isTaxInvoice=='1' ? '0.0' : '0'"
                                          title="Only numeric value allowed."
                                          v-model="item.price"
                                          pattern="((d+)((.d{1,2})?))$"
                                          :step="form.isTaxInvoice=='1' ? 0.1 : 0"
                                          :required="form.isTaxInvoice=='1' ? true : false"
                                          :min="form.isTaxInvoice=='1' ? 0.1 : 0"
                                      ></b-input>
                                  </b-input-group>
                              </b-form-group>
                            </div>


                            <div v-if="form.isTaxInvoice=='1' ? true : false" class="col-md-1 col-12">
                                <b-form-group :label="index > 0 ? '' : 'HSN'">
                                    <b-form-input
                                        placeholder="HSN"
                                        type="text"
                                        minlength="3"
                                        maxlength="6"
                                        v-model="item.hsn"
                                        title="HSN Number"
                                    ></b-form-input>
                                </b-form-group>
                            </div>


                            <div v-if="form.isTaxInvoice=='1' ? true : false" class="col-md-1 col-12">
                                <b-form-group :label="index > 0 ? '' : 'Discount'">
                                  <b-input-group append="₹">
                                    <b-form-input
                                        placeholder="0"
                                        type="number"
                                        min="0"
                                        step="0.01"
                                        v-model="item.discount"
                                        title="Discount"
                                    ></b-form-input>
                                  </b-input-group>
                                </b-form-group>
                            </div>


                            <div style="max-width: 12% !important;" class="col-md-2 col-12" v-if="form.isTaxInvoice=='1' ? true : false">

                                  <div class="col-md-10">
                                    <b-form-group :label="index > 0 ? '' : 'Tax %'">
                                        <b-form-input
                                            placeholder="0.00"
                                            type="number"
                                            max="100"
                                            min="0"
                                            step="0.01"
                                            v-model="item.taxRate"
                                            title="Tax %"
                                        ></b-form-input>
                                    </b-form-group>
                                  </div>

                            </div>

                            <div class="col">
                              <b-link
                                  @click="form.skuList.splice(index, 1);totalWeight()"
                                  class="btn-trash mt-16"
                                  v-b-tooltip.hover.right
                                  v-if="index>0"
                                  title="Delete item"
                                  >
                                  <i class="icon-trash"></i>
                              </b-link>
                            </div>

                        </div>
                    </li>
                </ul>
            </div>
            <div class="mx-auto text-left"></div>
        </div>

        <div class="row">
            <div class="col">
                <b-button
                    @click="form.skuList.push({
                      itemHeight: 0,
                      itemLength: 0,
                      itemWidth: 0,
                      itemWeight: 0,
                    });totalWeight()"
                    variant="tertiary"
                    class="mt-20 mb-48 ml-0 ml-md-14 w-sm-100"
                >
                    <i class="icon-add fs16 mr-10"></i>
                    Add Item
                </b-button>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="border-top pb-10 pl-14">

                    <div class="row mt-20">
                        <div class="col-md-7 col-12">
                            <span class="d-block fs18 lh28 fw500 mb-14">
                                Package Dimension
                            </span>

                            <div class="row">

                                <div class="col-md-3 col-6">
                                    <b-form-group
                                        id="length-field"
                                        label="Length (cm)"
                                        label-for="length"
                                        class="wsnw"
                                    >
                                        <b-input
                                            id="length"
                                            v-model="form.package.itemLength"
                                            type="number"
                                            max="999"
                                            min="1"
                                            placeholder="0"
                                            required
                                        ></b-input>
                                    </b-form-group>
                                </div>

                                <div class="col-md-3 col-6">
                                    <b-form-group
                                        id="width-field"
                                        label="Width (cm)"
                                        label-for="width"
                                        class="wsnw"
                                    >
                                        <b-input
                                            id="width"
                                            v-model="form.package.itemWidth"
                                            type="number"
                                            max="999"
                                            min="1"
                                            placeholder="0"
                                            required
                                        ></b-input>
                                    </b-form-group>
                                </div>

                                <div class="col-md-3 col-6">
                                    <b-form-group
                                        id="height-field"
                                        label="Height (cm)"
                                        label-for="height"
                                        class="wsnw"
                                    >
                                        <b-input
                                            id="height"
                                            v-model="form.package.itemHeight"
                                            type="number"
                                            max="999"
                                            min="1"
                                            placeholder="0"
                                            required
                                        ></b-input>
                                    </b-form-group>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-5 col-12">
                            <span class="d-block fs18 lh28 fw500 mb-14">
                                Package Weight
                            </span>

                            <div class="row">
                                <div class="col-md-5 col-12">
                                    <b-form-group
                                        id="length-field"
                                        label="Total Weight (kg)"
                                        label-for="length"
                                        class="wsnw"
                                    >
                                        <b-input
                                            id="totalWeight"
                                            v-model="form.packageWeight"
                                            type="number"
                                            placeholder="0.00"
                                            step=".01"
                                            :min="$store.state.minW"
                                            :max="$store.state.maxW"
                                            :disabled="totalWeights ? true : false"
                                            :required="totalWeights ? false : true"
                                        ></b-input>
                                    </b-form-group>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
      
      <div v-if="form.isTaxInvoice=='1' ? true : false" class="row">
        <div class="col">
            <div class="border-top pb-10 pl-14">

                <div class="row mt-20">
                    <div class="col-md-7 col-12">
                        <span class="d-block fs18 lh28 fw500 mb-14">
                            Additional Information
                        </span>

                        <div class="row">

                            <div class="col-md-3 col-6">
                                <b-form-group
                                    id="invoiceNumber-field"
                                    label="Invoice Number"
                                    label-for="invoiceNumber"
                                    class="wsnw"
                                >
                                    <b-input
                                        id="invoiceNumber"
                                        v-model="form.invoiceNumber"
                                        placeholder="Invoice number"
                                        type="text"
                                        title="Invoice number"
                                        required
                                        maxlength="15"
                                        minlength="3"
                                    ></b-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-3 col-6">
                                <b-form-group id="invoiceDate-field" label="Invoice Date" label-for="invoiceDate">
                                  <date-picker
                                      v-model="form.invoiceDate"
                                      id="invoiceDate"
                                      placeholder="yyyy-mm-dd"
                                      type="date"
                                      ref="invoiceDate"
                                      prefix-class="xmx"
                                      title="Invoice date"
                                      required
                                  >
                                      <i slot="icon-calendar" class="icon-calendar"></i>
                                  </date-picker>
                              </b-form-group>
                            </div>

                            <div class="col-md-3 col-6">
                                <b-form-group
                                    id="otherCharges-field"
                                    label="Shipping / Other Charges"
                                    label-for="otherCharges"
                                    class="wsnw"
                                >
                                  <b-input-group append="₹">
                                    <b-input
                                        id="otherCharges"
                                        v-model="form.otherCharges"
                                        placeholder="0.00"
                                        title="Shipping / Other charges"
                                        type="number"
                                        min="0"
                                        step="0.01"
                                    ></b-input>
                                  </b-input-group>
                                </b-form-group>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="d-flex my-16 mt-md-24 pl-14">
      <b-button type="submit" variant="primary" size="lg" class="w-sm-100" v-if = "$store.getters.user.choice == 1">
        Create Order
      </b-button>

      <b-button type="submit" variant="primary" size="lg" class="w-sm-100" v-if = "$store.getters.user.choice == 0">
        Book Order <b-spinner v-if="submitDisable" class="ml-10"></b-spinner>
      </b-button>

      <choiceDialog
        @openModal="openPricingModal"
        :formInfo="oldFormInfo"
        :choiceData="choiceData"
        ref="choiceDialog"
      />
      <reminderModal :reminderData.sync="reminderData" @openModal="openreminder" ref="reminderModal"/>
    </div>
    <p v-if="user.invoicing && showGstInvoice && form.orderType != 'REVERSE'" class="ml-14"><b>Note:</b> For customizing your labels and invoice <b-link to="/settings#Customization">Click here</b-link></p>
    </div>
  </div>
  </b-form>
</template>

<script>
    import DatePicker from "vue2-datepicker";
    import Multiselect from "vue-multiselect";
    import NumberInput from "@/components/NumberInput.vue";
    import choiceDialog from "@/components/choiceDialog.vue";
    import reminderModal from "@/components/reminderModal.vue";

    export default {
        name: "NewOrderSingleC",

        data() {
            return {
                customerAddress: "",
                maxLength: "140",
                warehouseAddress: "",
                email: null,
                customerList:[],
                orderDate: null,
                packageWeight: null,
                orderId: null,
                totalValue: null,
                sellerAddressId: null,
                allSelected: false,
                customerName: null,
                submitting: false,
                customerCity: null,
                customerContact: null,
                customerPinCode: null,
                singleOrderWarehouses:[],
                singleOrderPackages: [],
                options: [],
                orderType: null,
                submitDisable: false,
                value: null,
                categoryName: null,
                ewaybill: null,
                packageName: null,
                packageWidth: null,
                packageLength: null,
                packageHeight: null,
                mode: null,
                quantity: null,
                weight: null,
                selectedType: null,
                types: ["PREPAID", "COD", "REVERSE"],
                oldFormInfo: {},
                choiceData: [],
                showGstInvoice: true,
                form: {
                  customerAddress:'',
                  customerName:'',
                  email:'',
                  customerContact:'',
                  customerPinCode:'',
                  customerCity:'',
                  customerAddress: '',
                  orderDate: new Date(),
                  package: {},
                  skuList:[{
                    itemHeight: 0,
                    itemLength: 0,
                    itemWidth: 0,
                    itemWeight: 0,
                  }]
                },
                totalWeights: 0,
                totalPackageValue: 0,
                reminderData:{}
            };
        },
        components: {
            DatePicker,
            Multiselect,
            NumberInput,
            choiceDialog,
            reminderModal,
        },
        mounted() {
            const customerobj = Object.assign({},this.$store.state.customerObj)
            if (customerobj && customerobj.selected && customerobj.selected.name == 'Single') {
              let obj = {
                customerName:customerobj.name,
                email:customerobj.email,
                customerContact:customerobj.contact,
                customerPinCode:customerobj.pincode,
                customerCity:customerobj.city,
                customerAddress: customerobj.address,
                orderDate: new Date(),
                package: {},
                skuList:[{
                  itemHeight: 0,
                  itemLength: 0,
                  itemWidth: 0,
                  itemWeight: 0,
                }]
              }

              if (obj.email == null) {
                delete obj.email
              } 

              this.form = obj
              this.$store.commit('setCustomerObj',{})
            }

            this.getConsignors();

            if (this.$store.getters.user.choice == 0) {
                this.singleOrder()
            }
            else{
                this.choiceSingleOrder();
            }
        },
        
        methods: {
            async getConsignors() {
              try {
                let consignors = await this.axios.get('/consignor/getData?all=true')
                const consignorData = [...consignors.data.data.filter(i => i.status != 0).reverse()]
                this.$store.commit('setConsignors', consignorData)
              } catch(e) {
                this.popToast("failed", "Failed!", e.message);
                console.log(e);
              } 
            },
            fillCustomerfield(id) {
              let cust = this.customerList.filter(i => i.id == id)
              try {
                this.form.customerAddress = cust[0].address ? cust[0].address : ''
                this.form.customerName = cust[0].name ? cust[0].name : ''
                this.form.customerPinCode = cust[0].pincode ? cust[0].pincode : ''
                this.form.customerCity = cust[0].city ? cust[0].city : ''
                this.form.customerContact = cust[0].contact ? cust[0].contact : ''
                this.form.email = cust[0].email ? cust[0].email : ''

                this.customerList = null
              } catch(e) {
                // statements
                console.error(e);
              }
              
            },
            async getCustomers(customerName) {
              this.customerList = {}
              if (customerName && customerName.length > 3) {
                try {
                  const res = await this.axios.get(`/v4/customerAddressBook?page=1&offset=25&name=${customerName}`)
                  if (res.data.response.success) {
                    this.customerList = Object.assign([],res.data.response.data)
                  } else {}
                } catch(e) {
                  // statements
                  console.log(e);
                }
              }
              
            },
            openreminder() {
              this.$refs.reminderModal.isOpen = !this.$refs.reminderModal.isOpen
            },

            openautoDowngrade () {
              this.$refs.autoDowngrade.isOpen = !this.$refs.autoDowngrade.isOpen
            },

            toggleLock() {
              document.getElementById("app-content").classList.toggle("element-lock");
            },
    
            openPricingModal: function() {
              this.$refs.choiceDialog.isOpen = !this.$refs
                .choiceDialog.isOpen;
            },

            async choiceSingleOrder() {

                try {

                    const result = await this.axios.get("/choice/new");
                    this.$store.commit('setGlobal', {'csbRedirect':false})
                    this.$store.commit('setGlobal', {'csbRejected':false})

                    let singleOrderData = result.data;

                    if(result.data.downgradeNotification && Object.keys(result.data.downgradeNotification).length) {
                      if (document.cookie.indexOf("agreementPopupShown=true") == -1) {
                          this.reminderData = Object.assign({},result.data.downgradeNotification)
                          this.openreminder();
                          document.cookie = "agreementPopupShown=true; max-age=10";
                      }
                    }

                    this.singleOrderWarehouses = singleOrderData.address.map((i) => {
                        return {
                            name: i.name, 
                            sellerAddressId: i.id,
                            address: i.address,
                            originCity: i.city,
                            originPin: i.pincode,
                            $isDisabled: i.active == 1 && i.approved == 1 ? false: true,
                        };
                    })

                    this.singleOrderPackages = singleOrderData.categories.map((i) => {
                        return {
                            name: i,
                        };
                    });

                    this.singleOrderPackages.splice(18);

                }
                catch(error){
                      console.error(error);
                }

            },

            async singleOrder() {

                try {

                    const result = await this.axios.get("/choice/new");
                    this.$store.commit('setGlobal', {'csbRedirect':false})
                    let singleOrderData = result.data;

                    this.singleOrderWarehouses = singleOrderData.address.map((i) => {
                        return {
                            name: i.name, 
                            sellerAddressId: i.id,
                            address: i.address,
                            originCity: i.city,
                            originPin: i.pincode,
                            $isDisabled: i.active == 1 && i.approved == 1 ? false: true,
                        };
                    })

                    this.singleOrderPackages = singleOrderData.categories.map((i) => {
                        return {
                            name: i,
                        };
                    });

                }
                catch(error){
                      console.error(error);
                }

            },

            async formSubmit() {

              let formData = Object.assign({}, this.form);

                if (this.$store.getters.user.choice == 0) {

                    if (!formData.sellerAddressId) {
                        this.$refs.warehouse.$el.focus()
                        return false;
                    }
                    
                    if (!formData.orderDate) {
                        this.$refs.orderDate.$el.querySelector("input").focus();
                        return false;
                    }

                    if (!formData.otherCharges) {
                      formData.otherCharges = 0
                    }

                    if (!formData.orderType) {
                        this.$refs.orderType.$el.focus()
                        return false;
                    }

                    if (!formData.modeType) {
                        this.$refs.modeType.$el.focus()
                        return false;
                    }

                    if (formData.email == "" && formData.email.length == 0) {
                      delete formData.email
                    }

                    if (!formData.invoiceDate && this.$refs.invoiceDate) {
                        this.$refs.invoiceDate.$el.querySelector("input").focus();
                        return false;
                    }

                    if(formData.isTaxInvoice == 0) {
                      delete formData.invoiceDate
                      delete formData.invoiceNumber
                      delete formData.otherCharges
                      formData.skuList.map(i => {
                        delete i.taxRate,
                        delete i.hsn
                        delete i.discount
                      })
                    }
                    
                    formData.package.itemWeight = this.totalWeights;
                    formData.sellerAddressId = formData.sellerAddressId.sellerAddressId;
                    formData.orderType = formData.orderType.toLowerCase();
                    formData.modeType = formData.modeType.value;
                    formData.channel = "SINGLE"
                    if (formData.consignorID && formData.consignorID != null) {
                      formData.consignorID = formData.consignorID.id
                    } else {
                      if (formData.hasOwnProperty('consignorID')) {
                        delete formData.consignorID
                      }
                    }

                    try {

                        this.submitDisable = true

                        let tempData = Object.assign({}, formData);

                        let result = await this.axios.post("/orders/new", tempData);

                        if (result.data.message) {
                          this.popToast("booked", "Success!", result.data.message);

                          setTimeout(() => { 
                            this.$router.push('/shipments')
                          }, 2000)

                        }
                        if(result.data.error) {
                          this.popToast("failed", "Failed!", result.data.error);
                        }

                        this.submitDisable = false

                    }
                    catch(error){
                        this.submitDisable = false
                        console.error(error);
                    }
                }
                else {

                    if (!formData.sellerAddressId) {
                      this.$refs.warehouse.$el.focus()
                      return false;
                    }

                    if (!formData.otherCharges) {
                      formData.otherCharges = 0
                    }

                    if (!formData.orderDate) {
                        this.$refs.orderDate.$el.querySelector("input").focus();
                        return false;
                    }

                    if (!formData.orderType) {
                        this.$refs.orderType.$el.focus()
                        return false;
                    }

                    if (formData.email == "" && formData.email.length == 0) {
                      delete formData.email
                    }

                    if (!formData.invoiceDate && this.$refs.invoiceDate) {
                        this.$refs.invoiceDate.$el.querySelector("input").focus();
                        return false;
                    }

                    if(formData.isTaxInvoice == 0) {
                      delete formData.invoiceDate
                      delete formData.invoiceNumber
                      delete formData.otherCharges
                      formData.skuList.map(i => {
                        delete i.taxRate,
                        delete i.hsn
                        delete i.discount
                      })
                    }

                    formData.package.itemWeight = this.totalWeights;

                    formData.originCity = formData.sellerAddressId.originCity;
                    formData.originPin = formData.sellerAddressId.originPin;
                    formData.sellerAddressId = formData.sellerAddressId.sellerAddressId;
                    formData.orderType = formData.orderType.toLowerCase();

                    if (formData.consignorID && formData.consignorID != null) {
                      formData.consignorID = formData.consignorID.id
                    } else {
                      if (formData.hasOwnProperty('consignorID')) {
                        delete formData.consignorID
                      }
                    }

                    this.oldFormInfo = formData;

                    const newData = formData

                    newData.packageLength = formData.package.itemLength
                    newData.packageWidth  = formData.package.itemWidth
                    newData.packageHeight = formData.package.itemHeight
                    newData.packageWeight = formData.packageWeight
                    newData.itemWeight = formData.packageWeight


                    let total = 0
                    this.oldFormInfo.skuList.map(item => {
                      total += item.quantity ? parseFloat(item.quantity) : 0
                    })
                    newData.quantity = total

                    try {

                        let tempData = Object.assign({}, newData);

                        delete tempData.skuList;
                        delete tempData.package;

                        let result = await this.axios.post("/choice/getchoicedata", tempData);

                        if (result.data) {
                            this.choiceData = result.data.choicepricing;
                            this.openPricingModal();
                        }

                    }
                    catch(error){
                        console.error(error);
                    }

                }

            },

            totalWeight() {
                this.form.packageWeight = this.form.skuList.reduce((sum, item) => sum + parseFloat(item.weight || 0), 0)

                this.form.skuList.map(i => {

                    if(isNaN(this.form.packageWeight) || this.form.packageWeight == 0 || i.weight == undefined || !(i.weight) || i.weight == '') {
                        this.totalWeights = 0 // disabled false
                    }
                    else {
                        this.totalWeights = 1 // disabled true
                    }
                })

            },

            goBack() {
              this.$router.go(-1);
            },
        },
        computed: {
          user() {
            return this.$store.getters.user
          },
          singleorderconsignors() {
            return this.$store.state.consignors
          }
        },
        watch: {
          'form.sellerAddressId': function(newValue) {
            if(newValue) {
              this.warehouseAddress = this.form.sellerAddressId.address
            }else {
              this.warehouseAddress = ''
            }
          },
          'form.orderType': function(newVal) {
            if(newVal && newVal == 'REVERSE') {
              this.form.isTaxInvoice = 0
            }
          }
        }
    };

</script>

<style lang="scss" scoped>
  .search-results {
    position: absolute;
    margin: 0;
    padding: 0;
    top: 6.875rem;
    left: 0.80rem;
    width: calc(100% - 20.6rem);
    height: 13.1875rem;
    overflow-y: scroll;
    list-style-type: none;
    border-radius: 0.5rem;
    background-color: #fff;
    z-index: 1;
      .results {
        font-size: rem(10px);
        line-height: rem(12px);
        color: $gray-600;
        font-weight: 700;
      }
      li {
        cursor:pointer;
        font-size: rem(13px);
        line-height: rem(20px);
        border-top: 1px solid $gray-100;
        margin: 0 rem(5px);

        .result-item {
          position: relative;
          display: inline-flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          text-decoration: none;
          padding: rem(20px) 0 rem(21px) 0;
          .title {
            font-size: rem(13px);
            line-height: rem(20px);
            color: $gray-900;
            margin-right: rem(12px);
          }
          .status {
            font-size: rem(11px);
            line-height: rem(20px);
            color: $gray-600;
            margin-right: rem(12px);
          }
          .meta {
            font-size: rem(11px);
            line-height: rem(20px);
            color: $gray-600;
          }
          i {
            color: $gray-600;
            @include transition;
          }

          &:hover,
          &:focus,
          &:active {
            i {
              color: $blue;
            }
          }
          &:before {
            display: inline-flex;
            content: "";
            width: rem(4px);
            height: rem(4px);
            margin-left: rem(12px);
            margin-right: rem(8px);
            background-color: $blue;
            border-radius: 50%;
          }

          @include media-breakpoint-down(lg) {
            flex-direction: column;
            align-items: flex-start;
            padding: 1rem;
            i {
              position: absolute;
              top: calc(50% - 6px);
              right: rem(8px);
            }
            &:before {
              margin-left: 2px;
              margin-bottom: rem(12px);
            }
          }
        }

        .remove-query {
          color: $gray-600;
          display: inline;
          margin-left: auto;
          text-decoration: none;
          i {
            vertical-align: middle;
          }
          &:hover,
          &:focus,
          &:active {
            color: $red;
          }
        }
      }
    }
</style>