var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._g(
      {
        staticClass: "number-input",
        class:
          ((_obj = {
            "number-input--inline": _vm.inline,
            "number-input--center": _vm.center,
            "number-input--controls": _vm.controls
          }),
          (_obj["number-input--" + _vm.size] = _vm.size),
          _obj)
      },
      _vm.listeners
    ),
    [
      _vm.controls
        ? _c("button", {
            staticClass: "number-input__button number-input__button--minus",
            attrs: {
              type: "button",
              tabindex: "-1",
              disabled: _vm.disabled || _vm.readonly || !_vm.decreasable
            },
            on: { click: _vm.decrease }
          })
        : _vm._e(),
      _c(
        "input",
        _vm._b(
          {
            ref: "input",
            staticClass: "number-input__input",
            attrs: {
              type: "number",
              name: _vm.name,
              min: _vm.min,
              max: _vm.max,
              step: _vm.step,
              readonly: _vm.readonly || !_vm.inputtable,
              disabled: _vm.disabled || (!_vm.decreasable && !_vm.increasable),
              placeholder: _vm.placeholder,
              autocomplete: "off"
            },
            domProps: { value: _vm.currentValue },
            on: { change: _vm.change, paste: _vm.paste }
          },
          "input",
          _vm.attrs,
          false
        )
      ),
      _vm.controls
        ? _c("button", {
            staticClass: "number-input__button number-input__button--plus",
            attrs: {
              type: "button",
              tabindex: "-1",
              disabled: _vm.disabled || _vm.readonly || !_vm.increasable
            },
            on: { click: _vm.increase }
          })
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }