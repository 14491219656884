<template>
  <div class="new-order">
    <AppTopbar title="New Order" hideSearchNotif>
      <template v-slot:left-button>
        <b-button id="add-icon-sign" @click="openAddFundsModal" class="btn-sm add-icon mr-20"><i class="icon-add fs16 pl-0 pr-10"></i>{{  due | currency('inr') }}</b-button>
<!--         <b-button class="wsnw d-none d-md-block" variant="primary">
          Create Order
        </b-button> -->
         <b-popover 
          key="hover"
          target="add-icon-sign"
          placement="bottom"
          title="Popover!"

          style="z-index: 999;position: absolute;"
          custom-class="mw373 position-absolute z-99"
          triggers="hover">
          <div class="text-gray-600">
            <div>
              {{$store.getters.user.prepaid == 0 ? 'Total Due' : 'Usable Balance'}}  : {{  due | currency('inr') }}
            </div>
            <div>
              Click <i class="icon-add fs12 pl-3 pr-3"></i> to Add Funds
            </div>
          </div>
        </b-popover>
      </template>
      <template v-slot:right-button>
        <b-button variant="rounded" size="sm" @click="goBack">
          <i class="icon-close-variant fs14"></i>
        </b-button>
      </template>
    </AppTopbar>

    <div class="container-fluid pt-50 pt-md-80">
      <div class="row pt-14 pb-13 pb-md-14 pb-lg-20 pb-lg-24">
        <div class="col">
          <ul class="filter-list new-order">
            <li
              v-for="tab in tabs"
              :key="tab.id"
              :class="[{ active: $store.state.tab === tab.id }]"
            >
              <b-link @click="$store.commit('setTab', tab.id)" class="btn-filter" :class="tab.title">
                {{ tab.title }}
              </b-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <component :is="selectedTab.component"></component>

    <BillingAddFunds ref="BillingAddFunds" />
  </div>
</template>

<script>
import NewOrderMps from "@/components/NewOrderMps.vue";
import NewOrderBulk from "@/components/NewOrderBulk.vue";
import AppTopbar from "@/components/layout/AppTopbar.vue";
import NewOrderSingleC from "@/components/NewOrderSingleC.vue";
import BillingAddFunds from "@/components/BillingAddFunds.vue";
import NewOrderHyperlocal from "@/components/NewOrderHyperlocal.vue";
// import NewOrderInternational from "@/components/NewOrderInternational.vue";
import IntlShypmaxRedirect from "@/components/IntlShypmaxRedirect.vue";
import NewOrderB2B from "@/components/NewOrderB2B.vue";
export default {
  name: "NewOrder",
  title: "Shyplite - New Order",
  data() {
    return {
      tabs: [
        {
          id: 0,
          component: "NewOrderSingleC",
          title: "Single",
          createOrder: false,
        },
        {
          id: 1,
          component: "NewOrderBulk",
          title: "Bulk",
          createOrder: false,
        },
        {
          id: 3,
          component: "IntlShypmaxRedirect",
          // component: "NewOrderInternational",
          title: "International",
          createOrder: false,
        },
        // {
        //   id: 5,
        //   component: "NewOrderHyperlocal",
        //   title: "Hyperlocal",
        //   createOrder: false,
        // },
      ],
      selected: {
        id: 0,
        component: "NewOrderSingleC",
        title: "Single",
        createOrder: false,
      },
    };
  },
  components: {
    AppTopbar,
    NewOrderMps,
    NewOrderB2B,
    NewOrderBulk,
    BillingAddFunds,
    NewOrderSingleC,
    NewOrderHyperlocal,
    IntlShypmaxRedirect,
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    openAddFundsModal() {
      this.$refs.BillingAddFunds.isOpen = !this.$refs.BillingAddFunds.isOpen;
      this.userMenuToggle = true;
    },
  },
  created() {
    if(this.$store.getters.user.mpsStatus == 1) {
      this.tabs.push({
          id: 2,
          component: "NewOrderMps",
          title: "MPS",
          createOrder: false,
        })
    }
    if(this.$store.getters.user.b2bIsActive == 1) {
      this.tabs.push({
          id: 4,
          component: "NewOrderB2B",
          title: "B2B",
          createOrder: false,
      })
    }
    // if(this.$store.getters.user.choice == 0 && this.$store.getters.user.hlIsActive == 0) {
    //   this.tabs.splice(5, 1)
    // }
  },
  beforeDestroy() {
    this.$store.commit('setTab', 0)
  },
  computed: {
    selectedTab: function() {
      return this.tabs.find(i => i.id == this.$store.state.tab);
    },
    orderActive: function() {
      return this.selected.createOrder;
    },
    due() {
      return this.$store.getters.global.overview?.due || 0
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/ .card__content {
  overflow: visible;
}
</style>
