<template>
  <b-modal
    id="infoModal"
    centered
    v-model="isOpen"
    hide-footer
    size="lg"
  >
    <template v-slot:modal-header="{ close }">
      <div class=" text-center" style="width: 100%;">
        <span class="d-block mt-auto float-left fs24 lh28 fw500">
          Reminder - Monthly Recharge
        </span>
        <b-button class="float-right" size="sm" variant="rounded" @click="close()">
          <i class="icon-close-variant"></i>
        </b-button>
      </div>
    </template>
    <p class="mt-10 fs16">Please recharge with {{modalData.amountToRecharge | currency('inr') }} in {{modalData.noOfDaysRemaining}} day{{modalData.noOfDaysRemaining > 1 ? 's' : ''}} to continue with your current plan or it will be automatically downgraded to the best suited plan. </p>

    <div class="d-flex mt-20 justify-content-evenly">
      <div class="col-md-5 box mt-10" style="background:#e5f0ff">
        <p class="fs16 fw500">Current Plan</p>
        <span class="plan">{{modalData.currentPlan}}</span>
      </div>

      <div class="col-md-5 box mt-10 " style="background:#e5f0ff">
        <p class="fs16 fw500">Next Renewal Date</p>
        <span class="plan">{{modalData.nextRenewDate}}</span>
      </div>

      
    </div>


    <div class="d-flex mt-10 justify-content-evenly">

      <div class="col-md-5 box mt-10" style="background:#e5f0ff">
        <p class="fs16 fw500">Recharge since {{modalData.lastDateOfRecharge}}</p>
        <span class="plan">{{modalData.totalRechargeDone | currency('inr')}}</span>
      </div>


      <div class="col-md-5 box mt-10" style="background:#e5f0ff">
        <p class="fs16 fw500">Minimum Monthly Recharge</p>
        <span class="plan">{{modalData.monthlyMinimumRecharge | currency('inr')}}</span>
      </div>

      
    </div>


    <div class="pt-60 pt-md-60 text-center">
      <b-button @click="navigate('pricecalculator')" size="lg">
        View Details
      </b-button>
      <span class="mx-10"></span>
      <b-button @click="openAddfunds" variant="primary" size="lg">
        Make Payment of {{modalData.amountToRecharge | currency('inr') }}
      </b-button>
    </div>

  <BillingAddFunds ref="BillingAddFunds" />
  </b-modal>
</template>

<script>
import BillingAddFunds from "@/components/BillingAddFunds.vue";
export default {
  name: "reminderModal",
  props:{reminderData:Object},
  components: {
    BillingAddFunds
  },
  data() {
    return {
      isOpen: this.modalOpen
    };
  },
  props: {
    modalOpen: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    navigate(type) {
      this.$router.push(`/${type}`);
    },
    openAddfunds() {
      this.$store.commit('setGlobal', {planId:this.modalData.planID});
      this.$store.commit('setRateAmount', this.modalData.amountToRecharge.toFixed(2));
      this.$refs.BillingAddFunds.isOpen = !this.$refs.BillingAddFunds.isOpen;
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    modalData() {
      return Object.assign({},this.$attrs.reminderData)
    }
  }
};
</script>

<style lang="css" scoped>
  /deep/.modal-header {
      padding: 1.75rem 2.5rem 2rem 2.5rem
    }

    /deep/ .modal-lg {
      max-width: 43.25rem !important;
    }

    .plan {
          position: relative;
          top: 1rem;
    } 
 
 
    .box {
      color: #2D374B !important;
      padding: 1.5rem;
      text-align: center;
      position: relative;
      display: inline-block;
      width: 100px;
      height: 100px;
      border-radius: 5px;
      box-shadow: 0 6px 10px rgb(0 0 0 / 15%);
      /*transition: all 0.3s ease-in-out;*/
    }

/* Create the hidden pseudo-element */
/* include the shadow for the end state */
.box::after {
  content: '';
  position: absolute;
  z-index: -1;
  width: max-content;
  height: 100%;
  opacity: 0;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  /*transition: opacity 0.3s ease-in-out;*/
}

/* The fast way */

.make-it-fast {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
}


/* Pre-render the bigger shadow, but hide it */

.make-it-fast::after {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}


/* Transition to showing the bigger shadow on hover */

.make-it-fast:hover::after {
  opacity: 1;
}

/* Scale up the box */
/*.box:hover {
  transform: scale(1.2, 1.2);
}*/

/* Fade in the pseudo-element with the bigger shadow */
/*.box:hover::after {
  opacity: 1;
}*/

</style>
