var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.formSubmit($event)
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "container-fluid" },
        [
          _c("div", { staticClass: "card card__new-order mb-16 mb-md-12" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-6" }, [
                _c("div", { staticClass: "card__content" }, [
                  _c("div", { staticClass: "mx-auto" }, [
                    _c("span", { staticClass: "fs18 lh28 fw500" }, [
                      _vm._v(" Order Information ")
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        {
                          staticClass: "col",
                          class: {
                            "col-md-6":
                              _vm.$store.state.global.showConsignor &&
                              _vm.$store.state.global.showConsignorrequest
                          }
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "mt-12",
                              attrs: {
                                id: "warehouse-field",
                                label: "Warehouse",
                                "label-for": "warehouse"
                              }
                            },
                            [
                              _c("multiselect", {
                                ref: "warehouse",
                                attrs: {
                                  id: "warehouse",
                                  title: "Please select an item from the list",
                                  options: _vm.mpsOrderWarehouses,
                                  label: "name",
                                  required: "",
                                  searchable: true,
                                  "show-labels": false
                                },
                                model: {
                                  value: _vm.form.sellerAddressId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "sellerAddressId", $$v)
                                  },
                                  expression: "form.sellerAddressId"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.$store.state.global.showConsignor &&
                      _vm.$store.state.global.showConsignorrequest
                        ? _c(
                            "div",
                            { staticClass: "col-md-6 col" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "mt-12",
                                  attrs: {
                                    id: "warehouse-field",
                                    label: "Consignor",
                                    "label-for": "consignor"
                                  }
                                },
                                [
                                  _c("multiselect", {
                                    ref: "warehouse",
                                    staticClass: "select-neworder",
                                    attrs: {
                                      id: "consignor",
                                      title:
                                        "Please select an item from the list",
                                      options: _vm.singleorderconsignors,
                                      label: "name",
                                      searchable: true,
                                      "show-labels": false
                                    },
                                    model: {
                                      value: _vm.form.consignorID,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "consignorID", $$v)
                                      },
                                      expression: "form.consignorID"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "warehouse-address-field",
                                label: "Address",
                                "label-for": "warehouse-address"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "input-group-addon" },
                                [
                                  _c("b-form-textarea", {
                                    attrs: {
                                      id: "warehouse-address",
                                      type: "text",
                                      maxlength: this.maxLength,
                                      disabled: "",
                                      rows: "3",
                                      "max-rows": "6"
                                    },
                                    model: {
                                      value: _vm.warehouseAddress,
                                      callback: function($$v) {
                                        _vm.warehouseAddress = $$v
                                      },
                                      expression: "warehouseAddress"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: { id: "orderId", "label-for": "order-id" }
                            },
                            [
                              _c(
                                "label",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.right",
                                      modifiers: { hover: true, right: true }
                                    }
                                  ],
                                  staticClass: "wsnw",
                                  attrs: {
                                    for: "orderId",
                                    title:
                                      "Allowed special characters are # - _"
                                  }
                                },
                                [
                                  _vm._v(" Order ID "),
                                  _c("i", {
                                    staticClass:
                                      "icon-disclaimer fs12 text-gray-400"
                                  })
                                ]
                              ),
                              _c("b-form-input", {
                                attrs: {
                                  id: "order-id",
                                  title: "Allowed special characters are # - _",
                                  required: "",
                                  pattern: "[0-9a-z#A-Z_-]*",
                                  minlength: "2",
                                  maxlength: "35",
                                  placeholder: "Enter order id"
                                },
                                model: {
                                  value: _vm.form.orderId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "orderId", $$v)
                                  },
                                  expression: "form.orderId"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "date-field",
                                label: "Date",
                                "label-for": "date"
                              }
                            },
                            [
                              _c(
                                "date-picker",
                                {
                                  ref: "orderDate",
                                  attrs: {
                                    id: "date",
                                    placeholder: "yyyy-mm-dd",
                                    type: "date",
                                    "prefix-class": "xmx",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.form.orderDate,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "orderDate", $$v)
                                    },
                                    expression: "form.orderDate"
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "icon-calendar",
                                    attrs: { slot: "icon-calendar" },
                                    slot: "icon-calendar"
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "type-field",
                                label: "Order Type",
                                "label-for": " Order type"
                              }
                            },
                            [
                              _c("multiselect", {
                                attrs: {
                                  id: "type",
                                  value: "Prepaid",
                                  options: _vm.types,
                                  readonly: "true",
                                  searchable: false,
                                  "show-labels": false
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "total-value-field",
                                label: "Total Value",
                                "label-for": "total-value"
                              }
                            },
                            [
                              _c(
                                "b-input-group",
                                { attrs: { append: "₹" } },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      title:
                                        "Only numeric or decimal values allowed",
                                      id: "total-value",
                                      pattern: "((d+)((.d{1,2})?))$",
                                      type: "number",
                                      step: ".01",
                                      min: "1",
                                      placeholder: "0.00",
                                      required: ""
                                    },
                                    on: { input: _vm.inputValueCheck },
                                    model: {
                                      value: _vm.form.totalValue,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "totalValue", $$v)
                                      },
                                      expression: "form.totalValue"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.$store.getters.user.choice == 0
                        ? _c(
                            "div",
                            { staticClass: "col-md-6" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "mode-field",
                                    label: "Mode Type",
                                    "label-for": "mode"
                                  }
                                },
                                [
                                  _c("multiselect", {
                                    ref: "mode",
                                    attrs: {
                                      id: "mode",
                                      title:
                                        "Please select an item from the list",
                                      options: _vm.$store.getters.mode.mps,
                                      required: "",
                                      label: "name",
                                      searchable: false,
                                      "show-labels": false
                                    },
                                    model: {
                                      value: _vm.form.modeType,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "modeType", $$v)
                                      },
                                      expression: "form.modeType"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.totalValue >= 50000
                        ? _c(
                            "div",
                            { staticClass: "col-6" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "wsnw",
                                  attrs: {
                                    id: "length-field",
                                    label: "E-WayBill",
                                    "label-for": "ewaybill"
                                  }
                                },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      id: "ewaybill",
                                      type: "text",
                                      placeholder: "E-WayBill",
                                      required: ""
                                    },
                                    model: {
                                      value: _vm.form.ewaybill,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "ewaybill", $$v)
                                      },
                                      expression: "form.ewaybill"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "col-lg-6" }, [
                _c("div", { staticClass: "card__content" }, [
                  _c("div", { staticClass: "mx-auto" }, [
                    _c("span", { staticClass: "fs18 lh28 fw500" }, [
                      _vm._v(" Customer Information ")
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-12 col-md-6" },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "mt-12",
                              attrs: {
                                id: "customer-name-field",
                                label: "Name",
                                "label-for": "customer-name"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "customer-name",
                                  required: "",
                                  placeholder: "Enter customer name",
                                  maxlength: "35"
                                },
                                on: {
                                  keyup: function($event) {
                                    return _vm.getCustomers(
                                      _vm.form.customerName
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.form.customerName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "customerName", $$v)
                                  },
                                  expression: "form.customerName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.customerList && _vm.customerList.length
                        ? _c("div", { staticClass: "results-wrap" }, [
                            _c(
                              "ul",
                              {
                                staticClass: "search-results",
                                style:
                                  _vm.customerList &&
                                  _vm.customerList.length == 1
                                    ? "height: max-content !important"
                                    : ""
                              },
                              _vm._l(_vm.customerList, function(item) {
                                return _c(
                                  "li",
                                  { key: item.id, staticClass: "p-5 d-flex" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "w-100 col d-flex",
                                        on: {
                                          click: function($event) {
                                            return _vm.fillCustomerfield(
                                              item.id
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("div", [
                                          _c("p", [
                                            _c("span", [
                                              _c("strong", [_vm._v("Name")]),
                                              _vm._v(" - ")
                                            ]),
                                            _vm._v(_vm._s(item.name))
                                          ]),
                                          _c("p", [
                                            _c("span", [
                                              _c("strong", [_vm._v("Pincode")]),
                                              _vm._v(" - ")
                                            ]),
                                            _vm._v(_vm._s(item.pincode))
                                          ]),
                                          _c("p", [
                                            _c("span", [
                                              _c("strong", [_vm._v("Contact")]),
                                              _vm._v(" - ")
                                            ]),
                                            _vm._v(_vm._s(item.contact))
                                          ])
                                        ])
                                      ]
                                    )
                                  ]
                                )
                              }),
                              0
                            )
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "col-12 col-md-6" },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "mt-12",
                              attrs: {
                                label: "Email (Optional)",
                                "label-for": "customer-email"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "customer-email",
                                  placeholder: "Customer Email",
                                  pattern:
                                    "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$",
                                  title: "Olny valid email id is allowed.",
                                  type: "email"
                                },
                                model: {
                                  value: _vm.form.email,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "email", $$v)
                                  },
                                  expression: "form.email"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "customer-address-field",
                                label: "Address",
                                "label-for": "customer-address",
                                description:
                                  "Characters: " +
                                  _vm.form.customerAddress.length +
                                  " / " +
                                  this.maxLength
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "input-group-addon" },
                                [
                                  _c("b-form-textarea", {
                                    attrs: {
                                      id: "customer-address",
                                      type: "text",
                                      required: "",
                                      placeholder: "Enter customer address",
                                      maxlength: this.maxLength,
                                      rows: "3",
                                      "max-rows": "6"
                                    },
                                    model: {
                                      value: _vm.form.customerAddress,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "customerAddress",
                                          $$v
                                        )
                                      },
                                      expression: "form.customerAddress"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "city-field",
                                label: "City",
                                "label-for": "city"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "city",
                                  required: "",
                                  maxlength: "35",
                                  placeholder: "Enter city"
                                },
                                model: {
                                  value: _vm.form.customerCity,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "customerCity", $$v)
                                  },
                                  expression: "form.customerCity"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-6" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "pin-field",
                                label: "Pin Code",
                                "label-for": "pin"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "pin",
                                  placeholder: "110001",
                                  required: "",
                                  type: "text",
                                  maxlength: "6",
                                  minlength: "6",
                                  oninvalid:
                                    "this.setCustomValidity('Enter valid pincode.')",
                                  oninput: "this.setCustomValidity('')",
                                  formatter: _vm.numbers
                                },
                                model: {
                                  value: _vm.form.customerPinCode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "customerPinCode", $$v)
                                  },
                                  expression: "form.customerPinCode"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-6 col-12" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "phone-field",
                                label: "Mobile number",
                                "label-for": "phone-number"
                              }
                            },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "phone-number",
                                      required: "",
                                      type: "text",
                                      maxlength: "10",
                                      minlength: "10",
                                      formatter: _vm.numbers,
                                      placeholder: "8888888888",
                                      oninvalid:
                                        "this.setCustomValidity('Enter valid mobile number.')",
                                      oninput: "this.setCustomValidity('')"
                                    },
                                    model: {
                                      value: _vm.form.customerContact,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "customerContact",
                                          $$v
                                        )
                                      },
                                      expression: "form.customerContact"
                                    }
                                  }),
                                  _c(
                                    "div",
                                    { staticClass: "input-group-append" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "input-group-text" },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "icon-phone fs16 text-gray-600"
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "border-top w-100 mt-16 mb-16" }),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-lg-12" }, [
                _c("div", { staticClass: "card__content" }, [
                  _c("span", { staticClass: "d-block fs18 lh28 fw500 mb-14" }, [
                    _vm._v(" Package Information ")
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-3" },
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "mt-12",
                            attrs: {
                              id: "package-field",
                              label: "Package Name",
                              "label-for": "package-name"
                            }
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "package-name",
                                required: "",
                                maxlength: "100",
                                placeholder: "Enter package name",
                                type: "text"
                              },
                              model: {
                                value: _vm.form.packageName,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "packageName", $$v)
                                },
                                expression: "form.packageName"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ]),
                _vm.user.invoicing &&
                _vm.showGstInvoice &&
                _vm.form.orderType != "REVERSE"
                  ? _c(
                      "span",
                      {
                        staticClass: "d-block lh28 fw500 pl-14 mt-md-10 mt-10"
                      },
                      [
                        _vm._v(
                          " Do you want GST Compliant invoice along with label? "
                        ),
                        _c("b-form-checkbox", {
                          staticClass: "d-inline mr-20 ml-20 h2",
                          attrs: {
                            "unchecked-value": "0",
                            value: "1",
                            switch: ""
                          },
                          model: {
                            value: _vm.form.isTaxInvoice,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "isTaxInvoice", $$v)
                            },
                            expression: "form.isTaxInvoice"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "ul",
                  { staticClass: "package-items mt-md-20 mt-20" },
                  _vm._l(_vm.form.skuList, function(item, index) {
                    return _c("li", { key: index, staticClass: "pt-0 pb-0" }, [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          {
                            staticClass: " col-6",
                            class:
                              _vm.form.isTaxInvoice == "1"
                                ? "col-md-1"
                                : "col-md-2",
                            style:
                              _vm.form.isTaxInvoice == "1"
                                ? "flex: 0 0 9.33333%;max-width: 9.33333%;"
                                : ""
                          },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: index > 0 ? "" : "Identical Carton"
                                }
                              },
                              [
                                _c("b-input", {
                                  ref: "identicalBox",
                                  refInFor: true,
                                  attrs: {
                                    required: "",
                                    min: "1",
                                    type: "number",
                                    placeholder: "0",
                                    title: "Only numeric value allowed."
                                  },
                                  on: { input: _vm.totalIdentical },
                                  model: {
                                    value: item.identical,
                                    callback: function($$v) {
                                      _vm.$set(item, "identical", $$v)
                                    },
                                    expression: "item.identical"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: " col-6",
                            class:
                              _vm.form.isTaxInvoice == "1"
                                ? "col-md-1"
                                : "col-md-2",
                            style:
                              _vm.form.isTaxInvoice == "1"
                                ? "flex: 0 0 10.33333%;max-width: 10.33333%;"
                                : ""
                          },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: index > 0 ? "" : "Item Qty / Carton"
                                }
                              },
                              [
                                _c("b-input", {
                                  attrs: {
                                    required: "",
                                    placeholder: "0",
                                    min: 1,
                                    type: "number",
                                    title: "Only numeric value allowed."
                                  },
                                  on: { input: _vm.totalQtyCarton },
                                  model: {
                                    value: item.quantity,
                                    callback: function($$v) {
                                      _vm.$set(item, "quantity", $$v)
                                    },
                                    expression: "item.quantity"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: " col-6",
                            class:
                              _vm.form.isTaxInvoice == "1"
                                ? "col-md-1"
                                : "col-md-2",
                            style:
                              _vm.form.isTaxInvoice == "1"
                                ? "flex: 0 0 10.33333%;max-width: 10.33333%;"
                                : ""
                          },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: index > 0 ? "" : "Weight (kg) / Carton"
                                }
                              },
                              [
                                _c("b-input", {
                                  attrs: {
                                    required: "",
                                    step: ".01",
                                    min: _vm.$store.state.minW,
                                    max: _vm.$store.state.maxW,
                                    placeholder: "0.00",
                                    type: "number",
                                    title: "Only numeric value allowed."
                                  },
                                  on: { input: _vm.totalWeight },
                                  model: {
                                    value: item.packageWeight,
                                    callback: function($$v) {
                                      _vm.$set(item, "packageWeight", $$v)
                                    },
                                    expression: "item.packageWeight"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "col-md-4",
                            style:
                              _vm.form.isTaxInvoice == "1"
                                ? "flex: 0 0 31.33333%;max-width: 31.33333%;"
                                : ""
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "row justify-content-center" },
                              [
                                (_vm.form.isTaxInvoice == "1"
                                ? false
                                : true)
                                  ? _c("div", { staticClass: "col-md-1" })
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "col-md-3 col-6" },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: index > 0 ? "" : "Length (cm)"
                                        }
                                      },
                                      [
                                        _c("b-input", {
                                          attrs: {
                                            required: "",
                                            title:
                                              "Only numeric value allowed.",
                                            placeholder: "0",
                                            max: "999",
                                            min: "1",
                                            type: "number"
                                          },
                                          model: {
                                            value: item.packageLength,
                                            callback: function($$v) {
                                              _vm.$set(
                                                item,
                                                "packageLength",
                                                $$v
                                              )
                                            },
                                            expression: "item.packageLength"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-md-3 col-6" },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: index > 0 ? "" : "Width (cm)"
                                        }
                                      },
                                      [
                                        _c("b-input", {
                                          attrs: {
                                            title:
                                              "Only numeric value allowed.",
                                            required: "",
                                            placeholder: "0",
                                            max: "999",
                                            min: "1",
                                            type: "number"
                                          },
                                          model: {
                                            value: item.packageWidth,
                                            callback: function($$v) {
                                              _vm.$set(
                                                item,
                                                "packageWidth",
                                                $$v
                                              )
                                            },
                                            expression: "item.packageWidth"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-md-3 col-6" },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: index > 0 ? "" : "Height (cm)"
                                        }
                                      },
                                      [
                                        _c("b-input", {
                                          attrs: {
                                            title:
                                              "Only numeric value allowed.",
                                            max: "999",
                                            min: "1",
                                            required: "",
                                            placeholder: "0",
                                            type: "number"
                                          },
                                          model: {
                                            value: item.packageHeight,
                                            callback: function($$v) {
                                              _vm.$set(
                                                item,
                                                "packageHeight",
                                                $$v
                                              )
                                            },
                                            expression: "item.packageHeight"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                (_vm.form.isTaxInvoice == "1"
                                ? false
                                : true)
                                  ? _c("div", { staticClass: "col-md-1" })
                                  : _vm._e()
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "col-6 row",
                            class:
                              _vm.form.isTaxInvoice == "1"
                                ? "col-md-1"
                                : "col-md-2",
                            style:
                              _vm.form.isTaxInvoice == "1"
                                ? "flex: 0 0 9.33333%;max-width: 9.33333%;"
                                : ""
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "col-md-10 col-10" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: index > 0 ? "" : "Value (₹)"
                                    }
                                  },
                                  [
                                    _c("b-input", {
                                      attrs: {
                                        required: "",
                                        placeholder: "0.00",
                                        type: "number",
                                        pattern: "((d+)((.d{1,2})?))$",
                                        min: "1",
                                        required: "",
                                        step: ".01"
                                      },
                                      on: { input: _vm.inputValueCheck },
                                      model: {
                                        value: item.value,
                                        callback: function($$v) {
                                          _vm.$set(item, "value", $$v)
                                        },
                                        expression: "item.value"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            (_vm.form.isTaxInvoice == "1"
                            ? false
                            : true)
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-md-2 col-2 align-items-center"
                                  },
                                  [
                                    index > 0
                                      ? _c(
                                          "b-link",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName:
                                                  "v-b-tooltip.hover.right",
                                                modifiers: {
                                                  hover: true,
                                                  right: true
                                                }
                                              }
                                            ],
                                            staticClass: "btn-trash mt-16",
                                            attrs: { title: "Delete item" },
                                            on: {
                                              click: function($event) {
                                                _vm.form.skuList.splice(
                                                  index,
                                                  1
                                                )
                                                _vm.inputValueCheck()
                                                _vm.totalQtyCarton()
                                                _vm.totalWeight()
                                                _vm.totalIdentical()
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "icon-trash"
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        ),
                        (_vm.form.isTaxInvoice == "1"
                        ? true
                        : false)
                          ? _c(
                              "div",
                              { staticClass: "col-md-1 col-12" },
                              [
                                _c(
                                  "b-form-group",
                                  { attrs: { label: index > 0 ? "" : "HSN" } },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        placeholder: "HSN",
                                        type: "text",
                                        minlength: "3",
                                        maxlength: "6",
                                        title: "HSN Number"
                                      },
                                      model: {
                                        value: item.hsn,
                                        callback: function($$v) {
                                          _vm.$set(item, "hsn", $$v)
                                        },
                                        expression: "item.hsn"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        (_vm.form.isTaxInvoice == "1"
                        ? true
                        : false)
                          ? _c(
                              "div",
                              { staticClass: "col-md-1 col-12" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: index > 0 ? "" : "Discount"
                                    }
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      { attrs: { append: "₹" } },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            placeholder: "0",
                                            type: "number",
                                            min: "0",
                                            step: "0.01",
                                            title: "Discount"
                                          },
                                          model: {
                                            value: item.discount,
                                            callback: function($$v) {
                                              _vm.$set(item, "discount", $$v)
                                            },
                                            expression: "item.discount"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        (_vm.form.isTaxInvoice == "1"
                        ? true
                        : false)
                          ? _c(
                              "div",
                              {
                                staticClass: "col-md-2 col-12",
                                staticStyle: {
                                  flex: "0 0 10%",
                                  "max-width": "12% !important"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "col-md-10" },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label: index > 0 ? "" : "Tax %"
                                        }
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            placeholder: "0.00",
                                            type: "number",
                                            max: "100",
                                            min: "0",
                                            step: "0.01",
                                            title: "Tax %"
                                          },
                                          model: {
                                            value: item.taxRate,
                                            callback: function($$v) {
                                              _vm.$set(item, "taxRate", $$v)
                                            },
                                            expression: "item.taxRate"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            )
                          : _vm._e(),
                        (_vm.form.isTaxInvoice == "1"
                        ? true
                        : false)
                          ? _c("div", { staticClass: "col" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-md-2 col-2 align-items-center"
                                },
                                [
                                  index > 0
                                    ? _c(
                                        "b-link",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName:
                                                "v-b-tooltip.hover.right",
                                              modifiers: {
                                                hover: true,
                                                right: true
                                              }
                                            }
                                          ],
                                          staticClass: "btn-trash mt-16",
                                          attrs: { title: "Delete item" },
                                          on: {
                                            click: function($event) {
                                              _vm.form.skuList.splice(index, 1)
                                              _vm.inputValueCheck()
                                              _vm.totalQtyCarton()
                                              _vm.totalWeight()
                                              _vm.totalIdentical()
                                            }
                                          }
                                        },
                                        [_c("i", { staticClass: "icon-trash" })]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ])
                          : _vm._e()
                      ])
                    ])
                  }),
                  0
                ),
                _c("div", { staticClass: "row ml-10" }, [
                  _c("div", { staticClass: "col-2" }, [
                    _vm.totalIdenticalBox > 0
                      ? _c("div", { staticClass: "float-left cm mr-12" }, [
                          _vm._v(
                            "Total Identical Carton: " +
                              _vm._s(_vm.totalIdenticalBox)
                          )
                        ])
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "col-2" }, [
                    _vm.totalQty > 0
                      ? _c("div", { staticClass: "float-left cm mr-12" }, [
                          _vm._v("Total Item Qty: " + _vm._s(_vm.totalQty))
                        ])
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "col-2" }, [
                    _vm.rowTotalWeight > 0
                      ? _c("div", { staticClass: "float-left cm mr-12" }, [
                          _vm._v("Total Weight: " + _vm._s(_vm.rowTotalWeight))
                        ])
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "col-2" }),
                  _c("div", { staticClass: "col-2" }),
                  _c("div", { staticClass: "col-2" }, [
                    _vm.totalIdenticalBoxValue > 0
                      ? _c("div", { staticClass: "float-left cm mr-12" }, [
                          _vm._v(
                            "Total Value: " + _vm._s(_vm.totalIdenticalBoxValue)
                          )
                        ])
                      : _vm._e()
                  ])
                ])
              ]),
              _c("div", { staticClass: "mx-auto text-left" })
            ]),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "mt-20 mb-48 ml-0 ml-md-14 w-sm-100",
                      attrs: { variant: "tertiary" },
                      on: {
                        click: function($event) {
                          return _vm.form.skuList.push({
                            identical: "",
                            quantity: "",
                            packageWeight: "",
                            packageLength: "",
                            packageWidth: "",
                            packageHeight: "",
                            value: ""
                          })
                        }
                      }
                    },
                    [
                      _c("i", { staticClass: "icon-add fs16 mr-10" }),
                      _vm._v(" Add Carton ")
                    ]
                  )
                ],
                1
              )
            ]),
            (_vm.form.isTaxInvoice == "1"
            ? true
            : false)
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col" }, [
                    _c("div", { staticClass: "border-top pb-10 pl-14" }, [
                      _c("div", { staticClass: "row mt-20" }, [
                        _c("div", { staticClass: "col-md-7 col-12" }, [
                          _c(
                            "span",
                            { staticClass: "d-block fs18 lh28 fw500 mb-14" },
                            [_vm._v(" Additional Information ")]
                          ),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-md-3 col-6" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "wsnw",
                                    attrs: {
                                      id: "invoiceNumber-field",
                                      label: "Invoice Number",
                                      "label-for": "invoiceNumber"
                                    }
                                  },
                                  [
                                    _c("b-input", {
                                      attrs: {
                                        id: "invoiceNumber",
                                        placeholder: "Invoice number",
                                        type: "text",
                                        title: "Invoice number",
                                        required: "",
                                        maxlength: "15",
                                        minlength: "3"
                                      },
                                      model: {
                                        value: _vm.form.invoiceNumber,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "invoiceNumber",
                                            $$v
                                          )
                                        },
                                        expression: "form.invoiceNumber"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-3 col-6" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "invoiceDate-field",
                                      label: "Invoice Date",
                                      "label-for": "invoiceDate"
                                    }
                                  },
                                  [
                                    _c(
                                      "date-picker",
                                      {
                                        ref: "invoiceDate",
                                        attrs: {
                                          id: "invoiceDate",
                                          placeholder: "yyyy-mm-dd",
                                          type: "date",
                                          "prefix-class": "xmx",
                                          title: "Invoice date",
                                          required: ""
                                        },
                                        model: {
                                          value: _vm.form.invoiceDate,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "invoiceDate",
                                              $$v
                                            )
                                          },
                                          expression: "form.invoiceDate"
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "icon-calendar",
                                          attrs: { slot: "icon-calendar" },
                                          slot: "icon-calendar"
                                        })
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "col-md-3 col-6" },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    staticClass: "wsnw",
                                    attrs: {
                                      id: "otherCharges-field",
                                      label: "Shipping / Other Charges",
                                      "label-for": "otherCharges"
                                    }
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      { attrs: { append: "₹" } },
                                      [
                                        _c("b-input", {
                                          attrs: {
                                            id: "otherCharges",
                                            placeholder: "0.00",
                                            title: "Shipping / Other charges",
                                            type: "number",
                                            min: "0",
                                            step: "0.01"
                                          },
                                          model: {
                                            value: _vm.form.otherCharges,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.form,
                                                "otherCharges",
                                                $$v
                                              )
                                            },
                                            expression: "form.otherCharges"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              : _vm._e(),
            _c("div", { staticClass: "row d-flex flex-column" }, [
              _vm.submitDisable == true
                ? _c("span", { staticClass: "float-right text-danger ml-10" }, [
                    _vm._v("Total Value not matched*")
                  ])
                : _vm._e(),
              _c("span", {
                staticClass: "float-right text-danger ml-10",
                domProps: { innerHTML: _vm._s(_vm.minValueText) }
              }),
              _c(
                "div",
                { staticClass: "row ml-22" },
                [
                  _vm.$store.getters.user.choice == 1
                    ? _c(
                        "b-button",
                        {
                          staticClass: "w-sm-100 mt-10",
                          attrs: {
                            variant: "primary",
                            type: "submit",
                            size: "lg",
                            disabled: _vm.submitDisable
                          }
                        },
                        [
                          _vm._v(" Create Order "),
                          _vm.spinnerMPS
                            ? _c("b-spinner", { staticClass: "ml-10" })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.$store.getters.user.choice == 0
                    ? _c(
                        "b-button",
                        {
                          staticClass: "w-sm-100 mt-10",
                          attrs: {
                            variant: "primary",
                            type: "submit",
                            size: "lg",
                            disabled: _vm.submitDisable
                          }
                        },
                        [
                          _vm._v(" " + _vm._s(_vm.mpsBook) + " "),
                          _vm.spinnerMPS
                            ? _c("b-spinner", { staticClass: "ml-10" })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "card mb-20" }, [
            _c("div", { staticClass: "card__content" }, [
              _c("span", { staticClass: "fs18 lh24 fw500" }, [
                _vm._v(" Please Note: ")
              ]),
              _c("ul", { staticClass: "info-card mt-20" }, [
                _c("li", [
                  _c("span", [
                    _vm._v(
                      " Kindly declare correct weight and dimensions of each carton. "
                    )
                  ])
                ]),
                _c("li", [
                  _c("span", [
                    _vm._v(
                      " In case of non-identical cartons wrong declaration can lead to pickup issues. "
                    )
                  ])
                ]),
                _c("li", [
                  _c("span", [
                    _vm._v(
                      " Any MPS shipment shall not be eligible for weight dispute. "
                    )
                  ])
                ])
              ])
            ])
          ]),
          _c("choiceDialog", {
            ref: "choiceDialog",
            attrs: { formInfo: _vm.formInfo, choiceData: _vm.choiceData },
            on: { openModal: _vm.openPricingModal }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }