<template>
  <b-form @submit.prevent="formSubmit">
    <div class="container-fluid">
      <div class="card card__new-order mb-16 mb-md-12">
        <div class="row">
          <div class="col-lg-6">
            <div class="card__content">
              <div class="mx-auto">
                <span class="fs18 lh28 fw500">
                  Order Information
                </span>
                <div class="row">
                  <div class="col">
                    <b-form-group
                      id="warehouse-field"
                      label="Warehouse"
                      label-for="warehouse"
                      class="mt-12"
                    >
                      <multiselect
                        id="warehouse"
                        v-model="form.sellerAddressId"
                        :options="mpsOrderWarehouses"
                        label="name"
                        required
                        :show-labels="false"
                      ></multiselect>
                    </b-form-group>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <b-form-group
                      id="warehouse-address-field"
                      label="Address"
                      label-for="warehouse-address"
                    >
                      <div class="input-group-addon">
                        <b-form-textarea
                          id="warehouse-address"
                          type="text"
                          v-model="warehouseAddress"
                          :maxlength="this.maxLength"
                          disabled
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </div>
                    </b-form-group>
                  </div>
                </div>

                  <div class="row">
                    <div class="col">
                        <b-form-group
                        id="orderId"
                        label-for="order-id"
                      >
                      <label
                        for="orderId"
                        v-b-tooltip.hover.right
                        title="Allowed special characters are # - / _"
                        class="wsnw"
                      >
                        Order ID
                        <i class="icon-disclaimer fs12 text-gray-400"></i>
                      </label>
                      <b-form-input
                        id="order-id"
                        v-model="form.orderId"
                        pattern="[0-9a-z#A-Z_ /-]*"
                        maxlength="35"
                        title="Allowed special characters are # - / _"
                        required
                        placeholder="Enter order id"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 col-12">
                    <b-form-group id="date-field" label="Date" label-for="date">
                      <date-picker
                        prefix-class="xmx"
                        v-model="form.orderDate"
                        required
                        id="date"
                        placeholder="yyyy-mm-dd"
                      >
                        <i slot="icon-calendar" class="icon-calendar"></i>
                      </date-picker>
                    </b-form-group>
                  </div>

                  <div class="col-md-6 col-12">
                    <b-form-group id="type-field" label="Order Type" label-for="type">
                      <multiselect
                        id="type"
                        value="Prepaid"
                        :options="types"
                        readonly="true"
                        :searchable="false"
                        :show-labels="false"
                      ></multiselect>
                    </b-form-group>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 col-12">
                    <b-form-group
                      id="total-value-field"
                      label="Total Value"
                      label-for="total-value"
                    >
                      <b-input-group append="₹">
                        <b-input
                          v-model="form.totalValue"
                          @input="totalValueEnable"
                          id="total-value"
                          type="number"
                          placeholder="0.00"
                          step=".01"
                          min="1"
                          required
                        ></b-input>
                      </b-input-group>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="card__content">
              <div class="mx-auto">
                <span class="fs18 lh28 fw500">
                  Customer Information
                </span>
                <div class="row">
                  <div class="col">
                    <b-form-group
                      id="customer-name-field"
                      label="Name"
                      label-for="customer-name"
                      class="mt-12"
                    >
                      <b-form-input
                        id="customer-name"
                        v-model="form.customerName"
                        required
                        placeholder="Enter customer name"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <b-form-group
                      id="customer-address-field"
                      label="Address"
                      label-for="customer-address"
                      :description="
                        'Characters: ' +
                          form.customerAddress.length +
                          ' / ' +
                          this.maxLength
                      "
                    >
                      <div class="input-group-addon">
                        <b-form-textarea
                          id="customer-address"
                          type="text"
                          v-model="form.customerAddress"
                          required
                          placeholder="Enter customer address"
                          :maxlength="this.maxLength"
                          rows="3"
                          max-rows="6"
                        ></b-form-textarea>
                      </div>
                    </b-form-group>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <b-form-group id="city-field" label="City" label-for="city">
                      <b-form-input
                        id="city"
                        v-model="form.customerCity"
                        required
                        placeholder="Enter city"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                  <div class="col-md-6">
                    <b-form-group id="pin-field" label="Pin Code" label-for="pin">
                      <b-form-input
                        id="pin"
                        v-model="form.customerPinCode"
                        required
                        type="text"
                        placeholder="110001"
                        maxlength="6"
                        minlength="6"
                        oninvalid="this.setCustomValidity('Enter valid pincode.')"
                        oninput="this.setCustomValidity('')"
                        :formatter="numbers"
                      ></b-form-input>
                    </b-form-group>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 col-12">
                    <b-form-group
                      id="phone-field"
                      label="Mobile number"
                      label-for="phone-number"
                    >
                      <b-input-group>
                        <b-form-input
                          id="phone-number"
                          v-model="form.customerContact"
                          required
                          type="text"
                          maxlength="10"
                          minlength="10"
                          :formatter="numbers"
                          placeholder="8888888888"
                          oninvalid="this.setCustomValidity('Enter valid mobile number.')"
                          oninput="this.setCustomValidity('')"
                        ></b-form-input>
                        <div class="input-group-append">
                          <div class="input-group-text">
                            <i class="icon-phone fs16 text-gray-600"></i>
                          </div>
                        </div>
                      </b-input-group>
                    </b-form-group>
                  </div>
                  </div>
              </div>
            </div>
          </div>
        </div>

        <div class="border-top w-100 mt-16 mb-16"></div>

        <div class="row">
            <div class="col">
                <span class="d-block fs18 lh28 fw500 mb-14 pl-14">
                    Package Information
                </span>

                <ul class="package-items">
                    <li v-for="(item, index) in form.skuList" :key="index" class="pt-0 pb-0">
                        <div class="row">
                          <div class="col-md-3 col-12">
                            <div class="row">
                              <div class="col-1 col-md-1 d-flex" :class="{'align-items-center': index < 1 }">
                                <span class="fs16 lh19 fw500 mt-16">
                                  #{{ index + 1 }}
                                </span>
                              </div>
                              <div class="col-11 col-md-11">
                                <b-form-group :label="index > 0 ? '' : 'Item Name'">
                                  <b-form-input
                                  placeholder="Enter item name"
                                  type="text"
                                  maxlength="100"
                                  v-model="item.itemName"
                                  required
                                  ></b-form-input>
                                </b-form-group>
                              </div>
                            </div>

                          </div>

                            <div class="col-md-3 col-12">

                                <b-form-group :label="index > 0 ? '' : 'SKU (Optional)'">
                                    <b-form-input
                                        placeholder="Enter SKU name"
                                        type="text"
                                        maxlength="35"
                                        v-model="item.sku"
                                    ></b-form-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-2 col-6">
                                <b-form-group :label="index > 0 ? '' : 'Quantity'">
                                    <b-input
                                        min="1"
                                        max="100"
                                        type="number"
                                        v-model="item.quantity"
                                        class="new-order-number"
                                        placeholder="0"
                                        required
                                    ></b-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-2 col-6">
                                <b-form-group :label="index > 0 ? '' : 'Weight (kg) (Optional)'">
                                    <b-input 
                                        v-model="item.itemWeight" 
                                        @input="totalWeight" 
                                        type="number" 
                                        placeholder="0.00"
                                        step=".01"
                                        :min="$store.state.minW"
                                        :max="$store.state.maxW"
                                    ></b-input>
                                </b-form-group>
                            </div>

                            <div class="col-md-2 col-12">
                              <div class="row">
                                <div class="col-10 col-md-10">
                                  <b-form-group :label="index > 0 ? '' : 'Value (Optional)'">
                                      <b-input-group append="₹">
                                          <b-input 
                                              type="number" 
                                              placeholder="0.00"
                                              v-model="item.price"
                                              @input="totalPackValue"
                                              pattern="((d+)((.d{1,2})?))$"
                                              step=".01"
                                              min="1"
                                          ></b-input>
                                      </b-input-group>
                                  </b-form-group>

                                </div>
                                <div class="col-2 col-md-2">
                                  <b-link
                                      @click="form.skuList.splice(index, 1);totalWeight()"
                                      class="btn-trash mt-16"
                                      v-b-tooltip.hover.right
                                      v-if="index>0"
                                      title="Delete item"
                                  >
                                      <i class="icon-trash"></i>
                                  </b-link>
                                  
                                </div>
                              </div>
                            </div>

                        </div>
                    </li>
                </ul>
            </div>
            <div class="mx-auto text-left"></div>
        </div>

        <div class="row">
            <div class="col">
                <b-button
                    @click="form.skuList.push({
                      itemHeight: 0,
                      itemLength: 0,
                      itemWidth: 0,
                    })"
                    variant="tertiary"
                    class="mt-20 mb-48 ml-0 ml-md-14 w-sm-100"
                >
                    <i class="icon-add fs16 mr-10"></i>
                    Add Item
                </b-button>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <div class="border-top pb-10 pl-14">

                    <div class="row mt-20">
                        <div class="col-md-7 col-12">
                            <span class="d-block fs18 lh28 fw500 mb-14">
                                Package Dimension
                            </span>

                            <div class="row">

                                <div class="col-md-3 col-6">
                                    <b-form-group
                                        id="length-field"
                                        label="Length (cm)"
                                        label-for="length"
                                        class="wsnw"
                                    >
                                        <b-input
                                            id="length"
                                            v-model="form.package.itemLength"
                                            type="number"
                                            max="999"
                                            min="1"
                                            placeholder="0"
                                            required
                                        ></b-input>
                                    </b-form-group>
                                </div>

                                <div class="col-md-3 col-6">
                                    <b-form-group
                                        id="width-field"
                                        label="Width (cm)"
                                        label-for="width"
                                        class="wsnw"
                                    >
                                        <b-input
                                            id="width"
                                            v-model="form.package.itemWidth"
                                            type="number"
                                            max="999"
                                            min="1"
                                            placeholder="0"
                                            required
                                        ></b-input>
                                    </b-form-group>
                                </div>

                                <div class="col-md-3 col-6">
                                    <b-form-group
                                        id="height-field"
                                        label="Height (cm)"
                                        label-for="height"
                                        class="wsnw"
                                    >
                                        <b-input
                                            id="height"
                                            v-model="form.package.itemHeight"
                                            type="number"
                                            max="999"
                                            min="1"
                                            placeholder="0"
                                            required
                                        ></b-input>
                                    </b-form-group>
                                </div>

                            </div>
                        </div>
                        <div class="col-md-5 col-12">
                            <span class="d-block fs18 lh28 fw500 mb-14">
                                Package Weight
                            </span>

                            <div class="row">
                                <div class="col-md-5 col-12">
                                    <b-form-group
                                        id="length-field"
                                        label="Total Weight (kg)"
                                        label-for="length"
                                        class="wsnw"
                                    >
                                        <b-input
                                            id="totalWeight"
                                            :value="totalWeights"
                                            type="number"
                                            max="999"
                                            min="1"
                                            placeholder="0"
                                            :disabled="totalWeights ? true : false"
                                            :required="totalWeights ? false : true"
                                        ></b-input>
                                    </b-form-group>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row d-flex flex-column">
          <span class="float-right text-danger ml-16" v-if="submitDisable == true">Total Value not matched*</span>
          <div class="d-flex my-16 md-24 ml-16">
            <b-button
              variant="primary"
              type="submit"
              :disabled="submitDisable"
              size="lg"
              class="w-sm-100"
            >
              Create Order
            </b-button>
          </div>
        </div>
        </div>

      <div class="card mb-20">
        <div class="card__content">
          <span class="fs18 lh24 fw500">
            Please Note:
          </span>
          <ul class="info-card mt-20">
            <li>
              <span>
                  Allowed weight validation (20kg) can be applied or any note can be given below the Weight Field. - need to check the code
              </span>
            </li>
            <li>
              <span>
                  Pin Code validation to be applied to get the real time serviceability response. (Else "Check Availability" button to be given) - not finalised yet
              </span>
            </li>
          </ul>
        </div>
      </div>
      </div>

    </div>
  </b-form>
</template>

<script>
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import NumberInput from "@/components/NumberInput.vue";
import choiceDialog from "@/components/choiceDialog.vue";

export default {

  name: "NewOrderB2B",

  data() {

    return {
        totalIdenticalBox: '',
        totalValue: null,
        inputStep: 1,
        orderId: null,
        date: null,
        pincode:false,
        value: null,
        allSelected: false,
        maxLength: "140",
        warehouseAddress: "",
        customerAddress: "",
        identical: "identical",
        options: [],
        mpsOrderWarehouses: [],
        sellerAddressId: null,
        submitDisable: false,
        formInfo: [],
        choiceData: [],
        types: ["PREPAID"],
        singleOrderPackages: [],
        form: {
          customerAddress: '',
          orderDate: new Date(),
          identical: [],
          quantity: [],
          packageWeight: [],
          packageLength: [],
          packageWidth: [],
          packageHeight: [],
          value: [],
          package: {},
          skuList:[{
            itemHeight: 0,
            itemLength: 0,
            itemWidth: 0,
          }],
        },
        totalWeights: 0,
        totalPackageValue: 0,
        items: [
            {
              id: 1,
              quantity: "",
              weight: "",
              dimensions: { width: "", height: "", length: "" },
              value: "",
            },
        ],
        identicalFields: [
            {
              key: "identical",
              label: "Identical Boxes",
            },
            {
              key: "quantity",
              label: "Quantity / Box",
            },
            {
              key: "weight",
              label: "Weight(kg) / Carton",
            },
            {
              key: "dimensions",
            },
            {
              key: "value",
              label: "Value / Carton",
            },
            {
              key: "add_button",
              label: "",
            },
        ],
    };
  },
    components: {
        DatePicker,
        Multiselect,
        NumberInput,
        choiceDialog,
    },
    mounted() {
        this.choiceB2BOrder();
    },
    methods: {

        totalValueEnable() {
        
            let itemTotal = 0;
            let declearTotal = this.form.totalValue

            let totalItemArr = []
            let totalBox = 0
            let totalValue = 0
            this.items.map(item => {
                itemTotal = parseFloat(item.value) * parseFloat(item.identical)
                totalValue += itemTotal
                totalItemArr.push(itemTotal);
            })

            this.items.map(item => {
              totalBox += parseFloat(item.identical)
              this.totalIdenticalBox = totalBox
            })

            this.totalValue = totalValue

            const totalWeight = totalItemArr.reduce((a, b) => a + b, 0);

            if(declearTotal == totalWeight) {
                this.submitDisable = false;
            }
            else {
                this.submitDisable = true;
            }
        },

        totalPackValue() {

          let total = 0

          this.form.skuList.map(item => {
            total += item.price ? parseFloat(item.price) : 0
          })

          this.totalPackageValue = total
        },

        totalWeight() {
          let total = 0
          this.form.skuList.map(item => {
            total += item.itemWeight ? parseFloat(item.itemWeight) : 0
          })
          this.totalWeights = total
        },

        openPricingModal: function() {
            this.$refs.choiceDialog.isOpen = !this.$refs
            .choiceDialog.isOpen;
        },

        async choiceB2BOrder() {

            try {

                const result = await this.axios.get("/choice/b2b");

                let singleOrderData = result.data;

                this.mpsOrderWarehouses = singleOrderData.address.map((i) => {
                    return {
                        name: i.name, 
                        sellerAddressId: i.id,
                        address: i.address,
                        originCity: i.city,
                        originPin: i.pincode,
                        $isDisabled: i.active == 1 && i.approved == 1 ? false: true,
                    };
                })

                this.singleOrderPackages = singleOrderData.categories.map((i) => {
                    return {
                        name: i,
                    };
                });

                this.singleOrderPackages.splice(18);

            }
            catch(error){
                  console.error(error);
            }

        },

        async formSubmit() {

            let formData = Object.assign({}, this.form);

            formData.identical = []
            formData.quantity = []
            formData.packageWeight = []
            formData.packageLength = []
            formData.packageWidth = []
            formData.packageHeight = []
            formData.value = []


            for(let i = 0; i < this.items.length; i++){
                formData.quantity.push(this.items[i].quantity);
                formData.packageWeight.push(this.items[i].packageWeight);
                formData.packageLength.push(this.items[i].packageLength);
                formData.packageWidth.push(this.items[i].packageWidth);
                formData.packageHeight.push(this.items[i].packageHeight);
                formData.value.push(this.items[i].value);
                formData.identical.push(this.items[i].identical);
            }

            if (!formData.sellerAddressId) {
              this.popToast("failed", "Failed!","Please select a warehouse.");
              return false;
            }

            formData.originCity = formData.sellerAddressId.originCity;
            formData.originPin = formData.sellerAddressId.originPin;
            formData.orderType = "prepaid";
            formData.sellerAddressId = formData.sellerAddressId.sellerAddressId;
            formData.orderDate = new Date(formData.orderDate).toLocaleDateString(['en-US'], {year: "numeric",month: "2-digit",day: "2-digit"});

            formData.channel = 37

            this.formInfo = formData;

            try {

                let result = await this.axios.post("/choice/getchoicedatab2b", formData);

                if (result.data.response){
                    this.choiceData = result.data.response.response[0];
                    this.openPricingModal();
                }

            }
            catch(error){
                console.error(error);
            }

        },
        goBack() {
            this.$router.go(-1);
        },

        addItem() {
            let newItem = {

                quantity: this.items.quantity,
                weight: this.items.weight,
                width: this.items.width,
                height: this.items.height,
                length: this.items.length,
                value: this.items.value,
            };

            this.items.push(newItem);
        },
            removeItem: function(item) {
              const index = this.items.indexOf(item)
              this.items.splice(index, 1);
              this.totalValueEnable();
            },

    },
    watch: {
      'form.sellerAddressId': function(newValue) {
        if(newValue) {
          this.warehouseAddress = this.form.sellerAddressId.address
        }else {
          this.warehouseAddress = ''
        }
      }
    }
};
</script>

<style scoped>
.cm {
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
}
</style>
