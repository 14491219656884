<template>
    <b-form @submit.prevent="formSubmit">
        <div class="container-fluid">
            <div class="card card__new-order mb-16 mb-md-12">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="card__content">
                            <div class="mx-auto">

                                <span class="fs18 lh28 fw500">
                                  Order Information
                                </span>

                                <div class="row">
                                    <div class="col" :class="{'col-md-6': $store.state.global.showConsignor  && $store.state.global.showConsignorrequest }">
                                        <b-form-group
                                            id="warehouse-field"
                                            label="Warehouse"
                                            label-for="warehouse"
                                            class="mt-12"
                                        >
                                            <multiselect
                                                id="warehouse"
                                                v-model="form.sellerAddressId"
                                                title="Please select an item from the list"
                                                :options="mpsOrderWarehouses"
                                                label="name"
                                                ref="warehouse"
                                                required
                                                :searchable="true"
                                                :show-labels="false"
                                            ></multiselect>
                                        </b-form-group>
                                    </div>
                                    <div class="col-md-6 col" v-if="$store.state.global.showConsignor  && $store.state.global.showConsignorrequest">
                                        <b-form-group
                                          id="warehouse-field"
                                          label="Consignor"
                                          label-for="consignor"
                                          class="mt-12"
                                        >
                                          <multiselect
                                            id="consignor"
                                            title="Please select an item from the list"
                                            v-model="form.consignorID"
                                            :options="singleorderconsignors"
                                            label="name"
                                            ref="warehouse"
                                            class="select-neworder"
                                            :searchable="true"
                                            :show-labels="false"
                                          ></multiselect>
                                        </b-form-group>
                                      </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <b-form-group
                                            id="warehouse-address-field"
                                            label="Address"
                                            label-for="warehouse-address"
                                        >
                                            <div class="input-group-addon">
                                                <b-form-textarea
                                                    id="warehouse-address"
                                                    type="text"
                                                    v-model="warehouseAddress"
                                                    :maxlength="this.maxLength"
                                                    disabled
                                                    rows="3"
                                                    max-rows="6"
                                                ></b-form-textarea>
                                            </div>
                                        </b-form-group>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <b-form-group
                                            id="orderId"
                                            label-for="order-id"
                                        >
                                            <label
                                                for="orderId"
                                                v-b-tooltip.hover.right
                                                title="Allowed special characters are # - _"
                                                class="wsnw"
                                            >
                                                Order ID
                                                <i class="icon-disclaimer fs12 text-gray-400"></i>
                                            </label>
                                            <b-form-input
                                                id="order-id"
                                                v-model="form.orderId"
                                                title="Allowed special characters are # - _"
                                                required
                                                pattern="[0-9a-z#A-Z_-]*"
                                                minlength="2"
                                                maxlength="35"
                                                placeholder="Enter order id"
                                            ></b-form-input>
                                        </b-form-group>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <b-form-group id="date-field" label="Date" label-for="date">
                                            <date-picker
                                                v-model="form.orderDate"
                                                id="date"
                                                ref="orderDate"
                                                placeholder="yyyy-mm-dd"
                                                type="date"
                                                prefix-class="xmx"
                                                required
                                            >
                                                <i slot="icon-calendar" class="icon-calendar"></i>
                                            </date-picker>
                                        </b-form-group>
                                    </div>

                                    <div class="col-md-6">
                                        <b-form-group id="type-field" label="Order Type" label-for=" Order type">
                                            <multiselect
                                                id="type"
                                                value="Prepaid"
                                                :options="types"
                                                readonly="true"
                                                :searchable="false"
                                                :show-labels="false"
                                            ></multiselect>
                                        </b-form-group>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <b-form-group
                                            id="total-value-field"
                                            label="Total Value"
                                            label-for="total-value"
                                        >
                                            <b-input-group append="₹">
                                                <b-input
                                                    title="Only numeric or decimal values allowed"
                                                    v-model="form.totalValue"
                                                    id="total-value"
                                                    @input="inputValueCheck"
                                                    pattern="((d+)((.d{1,2})?))$"
                                                    type="number"
                                                    step=".01"
                                                    min="1"
                                                    placeholder="0.00"
                                                    required
                                                ></b-input>
                                            </b-input-group>
                                        </b-form-group>
                                    </div>

                                    <div class="col-md-6" v-if = "$store.getters.user.choice == 0">
                                        <b-form-group id="mode-field" label="Mode Type" label-for="mode">
                                            <multiselect
                                                id="mode"
                                                title="Please select an item from the list"
                                                v-model="form.modeType"
                                                :options="$store.getters.mode.mps"
                                                required
                                                ref="mode"
                                                label="name"
                                                :searchable="false"
                                                :show-labels="false"
                                          ></multiselect>
                                        </b-form-group>
                                    </div>

                                    <div class="col-6" v-if="form.totalValue >= 50000">
                                      <b-form-group
                                        id="length-field"
                                        label="E-WayBill"
                                        label-for="ewaybill"
                                        class="wsnw"
                                      >
                                        <b-input
                                          id="ewaybill"
                                          v-model="form.ewaybill"
                                          type="text"
                                          placeholder="E-WayBill"
                                          required
                                        ></b-input>
                                      </b-form-group>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="card__content">
                            <div class="mx-auto">
                                <span class="fs18 lh28 fw500">
                                    Customer Information
                                </span>

                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <b-form-group
                                            id="customer-name-field"
                                            label="Name"
                                            label-for="customer-name"
                                            class="mt-12"
                                        >
                                            <b-form-input
                                                id="customer-name"
                                                v-model="form.customerName"
                                                required
                                                placeholder="Enter customer name"
                                                maxlength="35"
                                                @keyup="getCustomers(form.customerName)"
                                            ></b-form-input>
                                        </b-form-group>
                                    </div>
                                    <div class="results-wrap" v-if="customerList && customerList.length">
                                        <ul class="search-results" :style=" customerList && customerList.length == 1 ?  'height: max-content !important' : '' ">
                                          <li v-for="item in customerList" class="p-5 d-flex" :key="item.id">
                                            <div class="w-100 col d-flex" @click="fillCustomerfield(item.id)">
                                                <div >
                                                  <p ><span><strong>Name</strong> - </span>{{ item.name }}</p>
                                                  <p ><span><strong>Pincode</strong> - </span>{{ item.pincode }}</p>
                                                  <p ><span><strong>Contact</strong> - </span>{{ item.contact }}</p>
                                                </div>
                                              </div>
                                          </li>
                                        </ul>
                                      </div>

                                    <div class="col-12 col-md-6">
                                        <b-form-group 
                                            label="Email (Optional)" 
                                            label-for="customer-email" 
                                            class="mt-12"
                                        >
                                            <b-form-input
                                                id="customer-email"
                                                v-model="form.email"
                                                placeholder="Customer Email"
                                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                                title="Olny valid email id is allowed."
                                                type="email"
                                            ></b-form-input>
                                        </b-form-group>
                                    </div>
                                </div>

                            <div class="row">
                              <div class="col">
                                <b-form-group
                                  id="customer-address-field"
                                  label="Address"
                                  label-for="customer-address"
                                  :description="
                                    'Characters: ' +
                                      form.customerAddress.length +
                                      ' / ' +
                                      this.maxLength
                                  "
                                >
                                  <div class="input-group-addon">
                                    <b-form-textarea
                                      id="customer-address"
                                      type="text"
                                      v-model="form.customerAddress"
                                      required
                                      placeholder="Enter customer address"
                                      :maxlength="this.maxLength"
                                      rows="3"
                                      max-rows="6"
                                    ></b-form-textarea>
                                  </div>
                                </b-form-group>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-md-6">
                                <b-form-group id="city-field" label="City" label-for="city">
                                  <b-form-input
                                    id="city"
                                    v-model="form.customerCity"
                                    required
                                    maxlength="35"
                                    placeholder="Enter city"
                                  ></b-form-input>
                                </b-form-group>
                              </div>
                              <div class="col-md-6">
                                <b-form-group id="pin-field" label="Pin Code" label-for="pin">
                                  <b-form-input
                                    id="pin"
                                    v-model="form.customerPinCode"
                                    placeholder="110001"
                                    required
                                    type="text"
                                    maxlength="6"
                                    minlength="6"
                                    oninvalid="this.setCustomValidity('Enter valid pincode.')"
                                    oninput="this.setCustomValidity('')"
                                    :formatter="numbers"
                                  ></b-form-input>
                                </b-form-group>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-md-6 col-12">
                                <b-form-group
                                  id="phone-field"
                                  label="Mobile number"
                                  label-for="phone-number"
                                >
                                  <b-input-group>
                                    <b-form-input
                                    id="phone-number"
                                    v-model="form.customerContact"
                                    required
                                    type="text"
                                    maxlength="10"
                                    minlength="10"
                                    :formatter="numbers"
                                    placeholder="8888888888"
                                    oninvalid="this.setCustomValidity('Enter valid mobile number.')"
                                    oninput="this.setCustomValidity('')"
                                    ></b-form-input>
                                    <div class="input-group-append">
                                      <div class="input-group-text">
                                        <i class="icon-phone fs16 text-gray-600"></i>
                                      </div>
                                    </div>
                                  </b-input-group>
                                </b-form-group>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="border-top w-100 mt-16 mb-16"></div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card__content">
                                    <span class="d-block fs18 lh28 fw500 mb-14">
                                        Package Information
                                    </span>

                                    <div class="row">
                                        <div class="col-12 col-md-3">
                                            <b-form-group
                                                id="package-field"
                                                label="Package Name"
                                                label-for="package-name"
                                                class="mt-12"
                                            >
                                                <b-form-input
                                                    id="package-name"
                                                    v-model="form.packageName"
                                                    required
                                                    maxlength="100"
                                                    placeholder="Enter package name"
                                                    type="text"
                                                ></b-form-input>
                                            </b-form-group>
                                        </div>
                                    </div>
                                </div>

                                <span v-if="user.invoicing && showGstInvoice && form.orderType != 'REVERSE'" class="d-block lh28 fw500 pl-14 mt-md-10 mt-10">
                                  Do you want GST Compliant invoice along with label?
                                  <b-form-checkbox unchecked-value="0" value="1" v-model="form.isTaxInvoice" class="d-inline mr-20 ml-20 h2" switch></b-form-checkbox>
                                </span>

                                <ul class="package-items mt-md-20 mt-20">
                                    <li v-for="(item, index) in form.skuList" :key="index" class="pt-0 pb-0">
                                        <div class="row">
                                            <div :style=" form.isTaxInvoice=='1' ?  'flex: 0 0 9.33333%;max-width: 9.33333%;' : '' " :class="form.isTaxInvoice=='1' ? 'col-md-1' : 'col-md-2'" class=" col-6">
                                                <b-form-group :label="index > 0 ? '' : 'Identical Carton'">
                                                    <b-input
                                                        v-model="item.identical"
                                                        required
                                                        min="1"
                                                        type="number"
                                                        placeholder="0"
                                                        ref="identicalBox"
                                                        @input="totalIdentical"
                                                        title="Only numeric value allowed."
                                                    ></b-input>
                                                </b-form-group>
                                            </div>

                                            <div :style=" form.isTaxInvoice=='1' ?  'flex: 0 0 10.33333%;max-width: 10.33333%;' : '' " :class="form.isTaxInvoice=='1' ? 'col-md-1' : 'col-md-2'" class=" col-6">
                                                <b-form-group :label="index > 0 ? '' : 'Item Qty / Carton' ">
                                                    <b-input
                                                        v-model="item.quantity"
                                                        required
                                                        placeholder="0"
                                                        :min="1"
                                                        type="number"
                                                        @input="totalQtyCarton"
                                                        title="Only numeric value allowed."
                                                    ></b-input>
                                                </b-form-group>
                                            </div>

                                            <div :style=" form.isTaxInvoice=='1' ?  'flex: 0 0 10.33333%;max-width: 10.33333%;' : '' " :class="form.isTaxInvoice=='1' ? 'col-md-1' : 'col-md-2'" class=" col-6">
                                                <b-form-group :label="index > 0 ? '' : 'Weight (kg) / Carton'">
                                                    <b-input
                                                        v-model="item.packageWeight"
                                                        required
                                                        step=".01"
                                                        :min="$store.state.minW"
                                                        :max="$store.state.maxW"
                                                        placeholder="0.00"
                                                        @input="totalWeight"
                                                        type="number"
                                                        title="Only numeric value allowed."
                                                    ></b-input>
                                                </b-form-group>
                                            </div>

                                            <div class="col-md-4" :style=" form.isTaxInvoice=='1' ?  'flex: 0 0 31.33333%;max-width: 31.33333%;' : '' ">
                                                <div class="row justify-content-center">
                                                    <div v-if="form.isTaxInvoice=='1' ? false : true" class="col-md-1"></div>
                                                    <div class="col-md-3 col-6">
                                                        <b-form-group :label="index > 0 ? '' : 'Length (cm)'">
                                                            <b-input
                                                            v-model="item.packageLength"
                                                            required
                                                            title="Only numeric value allowed."
                                                            placeholder="0"
                                                            max="999"
                                                            min="1"
                                                            type="number"

                                                            ></b-input>
                                                        </b-form-group>
                                                    </div>

                                                    <div class="col-md-3 col-6">
                                                        <b-form-group :label="index > 0 ? '' : 'Width (cm)'">
                                                            <b-input
                                                            v-model="item.packageWidth"
                                                            title="Only numeric value allowed."
                                                            required
                                                            placeholder="0"
                                                            max="999"
                                                            min="1"
                                                            type="number"
                                                            ></b-input>
                                                        </b-form-group>
                                                    </div>

                                                    <div class="col-md-3 col-6">
                                                        <b-form-group :label="index > 0 ? '' : 'Height (cm)'">
                                                            <b-input
                                                            v-model="item.packageHeight"
                                                            title="Only numeric value allowed."
                                                            max="999"
                                                            min="1" 
                                                            required
                                                            placeholder="0"
                                                            type="number"
                                                            ></b-input>
                                                        </b-form-group>
                                                    </div>
                                                    <div v-if="form.isTaxInvoice=='1' ? false : true" class="col-md-1"></div>
                                                </div>
                                            </div>

                                            <div :style=" form.isTaxInvoice=='1' ? 'flex: 0 0 9.33333%;max-width: 9.33333%;' : '' " :class="form.isTaxInvoice=='1' ? 'col-md-1' : 'col-md-2'" class="col-6 row">
                                                    <div class="col-md-10 col-10">
                                                        <b-form-group :label="index > 0 ? '' : 'Value (₹)'">
                                                            <b-input
                                                                v-model="item.value"
                                                                required
                                                                placeholder="0.00"
                                                                type="number"
                                                                @input="inputValueCheck"
                                                                pattern="((d+)((.d{1,2})?))$"
                                                                min="1"
                                                                required
                                                                step=".01"
                                                            ></b-input>
                                                        </b-form-group> 
                                                    </div>
                                                    <div v-if="form.isTaxInvoice=='1' ? false : true" class="col-md-2 col-2 align-items-center">
                                                       <b-link
                                                            @click="form.skuList.splice(index, 1);
                                                            inputValueCheck();
                                                            totalQtyCarton();
                                                            totalWeight();
                                                            totalIdentical()"
                                                            class="btn-trash mt-16"
                                                            v-b-tooltip.hover.right
                                                            v-if="index>0"
                                                            title="Delete item"
                                                       >
                                                       <i class="icon-trash"></i>
                                                        </b-link>
                                                   </div>
                                            </div>


                                            <div v-if="form.isTaxInvoice=='1' ? true : false" class="col-md-1 col-12">
                                                <b-form-group :label="index > 0 ? '' : 'HSN'">
                                                    <b-form-input
                                                        placeholder="HSN"
                                                        type="text"
                                                        minlength="3"
                                                        maxlength="6"
                                                        v-model="item.hsn"
                                                        title="HSN Number"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </div>


                                            <div v-if="form.isTaxInvoice=='1' ? true : false" class="col-md-1 col-12">
                                                <b-form-group :label="index > 0 ? '' : 'Discount'">
                                                  <b-input-group append="₹">
                                                    <b-form-input
                                                        placeholder="0"
                                                        type="number"
                                                        min="0"
                                                        step="0.01"
                                                        v-model="item.discount"
                                                        title="Discount"
                                                    ></b-form-input>
                                                  </b-input-group>
                                                </b-form-group>
                                            </div>


                                            <div style="flex: 0 0 10%;max-width: 12% !important;" class="col-md-2 col-12" v-if="form.isTaxInvoice=='1' ? true : false">

                                                  <div class="col-md-10">
                                                    <b-form-group :label="index > 0 ? '' : 'Tax %'">
                                                        <b-form-input
                                                            placeholder="0.00"
                                                            type="number"
                                                            max="100"
                                                            min="0"
                                                            step="0.01"
                                                            v-model="item.taxRate"
                                                            title="Tax %"
                                                        ></b-form-input>
                                                    </b-form-group>
                                                  </div>

                                            </div>


                                            <div class="col" v-if="form.isTaxInvoice=='1' ? true : false">
                                                <div class="col-md-2 col-2 align-items-center">
                                                       <b-link
                                                            @click="form.skuList.splice(index, 1);
                                                            inputValueCheck();
                                                            totalQtyCarton();
                                                            totalWeight();
                                                            totalIdentical()"
                                                            class="btn-trash mt-16"
                                                            v-b-tooltip.hover.right
                                                            v-if="index>0"
                                                            title="Delete item"
                                                       >
                                                       <i class="icon-trash"></i>
                                                        </b-link>
                                                   </div>
                                            </div>

                                        </div>
                                    </li>
                                </ul>

                                <div class="row ml-10">
                                    <div class="col-2">
                                        <div v-if="totalIdenticalBox > 0" class="float-left cm mr-12">Total Identical Carton: {{ totalIdenticalBox }}</div>
                                    </div>
                                    <div class="col-2">
                                        <div v-if="totalQty > 0" class="float-left cm mr-12">Total Item Qty: {{ totalQty }}</div>
                                    </div>                        
                                    <div class="col-2">
                                        <div v-if="rowTotalWeight > 0" class="float-left cm mr-12">Total Weight: {{ rowTotalWeight }}</div>
                                    </div>                        
                                    <div class="col-2">
                                    </div>                        
                                    <div class="col-2">
                                    </div>                        
                                    <div class="col-2">
                                        <div v-if="totalIdenticalBoxValue > 0" class="float-left cm mr-12">Total Value: {{ totalIdenticalBoxValue }}</div>
                                    </div>
                                </div>

                            </div>
                            <div class="mx-auto text-left"></div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <b-button
                                    @click="form.skuList.push({
                                      identical: '',
                                      quantity: '',
                                      packageWeight: '',
                                      packageLength: '',
                                      packageWidth: '',
                                      packageHeight: '',
                                      value: '',
                                    })"
                                    variant="tertiary"
                                    class="mt-20 mb-48 ml-0 ml-md-14 w-sm-100"
                                >
                                    <i class="icon-add fs16 mr-10"></i>
                                    Add Carton
                                </b-button>
                            </div>
                        </div>

                        <div v-if="form.isTaxInvoice=='1' ? true : false" class="row">
                            <div class="col">
                                <div class="border-top pb-10 pl-14">

                                    <div class="row mt-20">
                                        <div class="col-md-7 col-12">
                                            <span class="d-block fs18 lh28 fw500 mb-14">
                                                Additional Information
                                            </span>

                                            <div class="row">

                                                <div class="col-md-3 col-6">
                                                    <b-form-group
                                                        id="invoiceNumber-field"
                                                        label="Invoice Number"
                                                        label-for="invoiceNumber"
                                                        class="wsnw"
                                                    >
                                                        <b-input
                                                            id="invoiceNumber"
                                                            v-model="form.invoiceNumber"
                                                            placeholder="Invoice number"
                                                            type="text"
                                                            title="Invoice number"
                                                            required
                                                            maxlength="15"
                                                            minlength="3"
                                                        ></b-input>
                                                    </b-form-group>
                                                </div>

                                                <div class="col-md-3 col-6">
                                                    <b-form-group id="invoiceDate-field" label="Invoice Date" label-for="invoiceDate">
                                                      <date-picker
                                                          v-model="form.invoiceDate"
                                                          id="invoiceDate"
                                                          placeholder="yyyy-mm-dd"
                                                          type="date"
                                                          ref="invoiceDate"
                                                          prefix-class="xmx"
                                                          title="Invoice date"
                                                          required
                                                      >
                                                          <i slot="icon-calendar" class="icon-calendar"></i>
                                                      </date-picker>
                                                  </b-form-group>
                                                </div>

                                                <div class="col-md-3 col-6">
                                                    <b-form-group
                                                        id="otherCharges-field"
                                                        label="Shipping / Other Charges"
                                                        label-for="otherCharges"
                                                        class="wsnw"
                                                    >
                                                      <b-input-group append="₹">
                                                        <b-input
                                                            id="otherCharges"
                                                            v-model="form.otherCharges"
                                                            placeholder="0.00"
                                                            title="Shipping / Other charges"
                                                            type="number"
                                                            min="0"
                                                            step="0.01"
                                                        ></b-input>
                                                      </b-input-group>
                                                    </b-form-group>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="row d-flex flex-column">
                            <span class="float-right text-danger ml-10" v-if="submitDisable == true">Total Value not matched*</span>
                            <span class="float-right text-danger ml-10" v-html="minValueText"></span>
                            <div class="row ml-22">
                                <b-button
                                    variant="primary"
                                    type="submit"
                                    size="lg"
                                    class="w-sm-100 mt-10"
                                    :disabled="submitDisable"
                                    v-if = "$store.getters.user.choice == 1"
                                >
                                    Create Order <b-spinner v-if="spinnerMPS" class="ml-10"></b-spinner>
                                </b-button>

                                <b-button
                                    variant="primary"
                                    type="submit"
                                    size="lg"
                                    :disabled="submitDisable"
                                    class="w-sm-100 mt-10"
                                    v-if = "$store.getters.user.choice == 0"
                                >
                                    {{ mpsBook }} <b-spinner v-if="spinnerMPS" class="ml-10"></b-spinner>
                                </b-button>
                            </div>
                        </div>
                    </div>

                <div class="card mb-20">
                    <div class="card__content">
                        <span class="fs18 lh24 fw500">
                            Please Note:
                        </span>
                        <ul class="info-card mt-20">
                            <li>
                                <span>
                                    Kindly declare correct weight and dimensions of each carton. 
                                </span>
                            </li>
                            <li>
                                <span>
                                    In case of non-identical cartons wrong declaration can lead to pickup issues. 
                                </span>
                            </li>
                            <li>
                                <span>
                                    Any MPS shipment shall not be eligible for weight dispute. 
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <choiceDialog
                    @openModal="openPricingModal"
                    :formInfo="formInfo"
                    :choiceData="choiceData"
                    ref="choiceDialog"
                />
            </div>
        </b-form>
    </template>

<script>
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";
import NumberInput from "@/components/NumberInput.vue";
import choiceDialog from "@/components/choiceDialog.vue";

export default {

  name: "NewOrderMps",

  data() {

    return {

        totalQty: '',
        rowTotalWeight: '',
        totalIdenticalBox: '',
        totalIdenticalBoxValue: '',
        inputStep: 1,
        orderId: null,
        email: null,
        date: '17-02-2021',
        value: null,
        allSelected: false,
        maxLength: "140",
        warehouseAddress: "",
        customerAddress: "",
        customerList:[],
        minValueText: '',
        options: [],
        showGstInvoice: false,
        mpsOrderWarehouses: [],
        mpsBook: 'Book Order',
        submitDisable: false,
        spinnerMPS:false,
        sellerAddressId: null,
        formInfo: {},
        choiceData: [],
        types: ["PREPAID"],
        singleOrderPackages: [],
        form: {
            customerAddress:'',
            orderDate: new Date(),
            quantity: [],
            skuList:[{
              identical: '',
              quantity: '',
              packageWeight: '',
              packageLength: '',
              packageWidth: '',
              packageHeight: '',
              value: '',
            }],
            packageWeightArr: [],
            packageWeight: [],
            packageLength: [],
            packageWidth: [],
            packageHeight: [],
            value: [],
        },
        items: [
            {
                id: 1,
                quantity: "",
                weight: "",
                dimensions: { width: "", height: "", length: "" },
                value: "",
            },
            {
                id: 2,
                quantity: "",
                weight: "",
                dimensions: { width: "", height: "", length: "" },
                value: "",
            },
        ],
        identicalFields: [
            {
              key: "identical",
              label: "Identical Boxes",
            },
            {
              key: "quantity",
              label: "Item Qty / Carton",
            },
            {
              key: "weight",
              label: "Weight (kg) / Carton",
            },
            {
              key: "dimensions",
            },
            {
              key: "value",
              label: "Value / Carton (₹)",
              thClass: "wsnw",
            },
            {
              key: "add_button",
              label: "",
            },
        ],
    };
  },
    components: {
        DatePicker,
        Multiselect,
        NumberInput,
        choiceDialog,
    },
    computed: {
          user() {
            return this.$store.getters.user
          },
          singleorderconsignors() {
            return this.$store.state.consignors
          }
        },
    mounted() {
        const customerobj = Object.assign({},this.$store.state.customerObj)
        if (customerobj && customerobj.selected && customerobj.selected.name == 'MPS') {
          let obj = {
            customerName:customerobj.name,
            email:customerobj.email,
            customerContact:customerobj.contact,
            customerPinCode:customerobj.pincode,
            customerCity:customerobj.city,
            customerAddress: customerobj.address,
            orderDate: new Date(),
            quantity: [],
            skuList:[{
              identical: '',
              quantity: '',
              packageWeight: '',
              packageLength: '',
              packageWidth: '',
              packageHeight: '',
              value: '',
            }],
            packageWeightArr: [],
            packageWeight: [],
            packageLength: [],
            packageWidth: [],
            packageHeight: [],
            value: [],
          }

          if (obj.email == null) {
            delete obj.email
          } 

          this.form = obj
          this.$store.commit('setCustomerObj',{})
        }

        if (this.$store.getters.user.choice == 0) {
            this.mpsOrder()
        }
        else{
            this.choiceMpsOrder();
        }
    },
    methods: {
        fillCustomerfield(id) {

            let cust = this.customerList.filter(i => i.id == id)

            try {
                this.form.customerAddress = cust[0].address ? cust[0].address : ''
                this.form.customerName = cust[0].name ? cust[0].name : ''
                this.form.customerPinCode = cust[0].pincode ? cust[0].pincode : ''
                this.form.customerCity = cust[0].city ? cust[0].city : ''
                this.form.customerContact = cust[0].contact ? cust[0].contact : ''
                this.form.email = cust[0].email ? cust[0].email : ''
                this.customerList = null
            } catch(e) {
              // statements
              console.error(e);
            }
            
          },

          async getCustomers(customerName) {

            this.customerList = {}

            if (customerName && customerName.length > 3) {

              try {
                const res = await this.axios.get(`/v4/customerAddressBook?page=1&offset=25&name=${customerName}`)
                if (res.data.response.success) {
                  this.customerList = Object.assign([],res.data.response.data)
                } else {}

              } catch(e) {
                // statements
                console.log(e);
              }
            }
            
          },

        totalIdentical() {

            let identical = 0;

            this.form.skuList.map(item => {
              identical += item.identical ? parseFloat(item.identical) : 0
            })

            this.totalIdenticalBox = identical;

            this.totalQtyCarton();
        },

        totalQtyCarton() {

            let values = this.form.skuList.map((i,idx) => {

                return parseFloat(i.identical)*parseFloat(i.quantity)

            }).map(Number).filter(Boolean);

            const total = values.reduce((a, b) => a + b, 0);

            this.totalQty = total;

            this.totalWeight();
        },

        totalWeight() {

            let values = this.form.skuList.map((i,idx) => {

                return parseFloat(i.packageWeight)*parseFloat(i.identical)

            }).map(Number).filter(Boolean);

            const total = values.reduce((a, b) => a + b, 0);

            this.rowTotalWeight = total;
        },

        openPricingModal: function() {
            this.$refs.choiceDialog.isOpen = !this.$refs
            .choiceDialog.isOpen;
        },

        async choiceMpsOrder() {

            try {

                const result = await this.axios.get("/choice/mps");

                let singleOrderData = result.data;

                this.mpsOrderWarehouses = singleOrderData.address.map((i) => {
                    return {
                        name: i.name, 
                        sellerAddressId: i.id,
                        address: i.address,
                        originCity: i.city,
                        originPin: i.pincode,
                        $isDisabled: i.active == 1 && i.approved == 1 ? false: true,
                    };
                })

            }
            catch(error){
                  console.error(error);
            }

        },

        async mpsOrder() {

            try {

                const result = await this.axios.get("/orders/new");

                let singleOrderData = result.data;

                this.mpsOrderWarehouses = singleOrderData.address.map((i) => {
                    return {
                        name: i.name, 
                        sellerAddressId: i.id,
                        address: i.address,
                        $isDisabled: i.active == 1 && i.approved == 1 ? false: true,
                        // originCity: i.city,
                        // originPin: i.pincode
                    };
                })
            }
            catch(error){
                  console.error(error);
            }

        },

        inputValueCheck() {
            let total = 0;
            let declearTotal = this.form.totalValue
            this.form.skuList.map(item => {
                total += parseFloat(item.value)
            })

            this.totalIdenticalBoxValue = total;

            if(declearTotal != undefined && !isNaN(total)){
                if(declearTotal == total) {
                    this.submitDisable = false;
                }
                else {
                    this.submitDisable = true;
                }
            }
        },

        async formSubmit() {

            let totalIdentical = 0;

            this.form.skuList.map(item => {
                totalIdentical += item.identical ? parseFloat(item.identical) : 0
            })

            if (totalIdentical == 1) {
                this.minValueText = 'Minimum value of total identical carton is 2';
                return false;
            }
            else {
                this.minValueText = '';
            }

            let formData = Object.assign({}, this.form);

              formData.quantity = []
              formData.packageWeightArr = []
              formData.packageWeight = []
              formData.packageLength = []
              formData.packageWidth = []
              formData.packageHeight = []
              formData.value = []


            for(let i = 0; i < this.items.length; i++){
                formData.quantity.push(parseFloat(this.items[i].quantity));
                formData.packageWeightArr.push(parseFloat(this.items[i].packageWeight));
                formData.packageWeight.push(parseFloat(this.items[i].packageWeight));
                formData.packageLength.push(parseFloat(this.items[i].packageLength));
                formData.packageWidth.push(parseFloat(this.items[i].packageWidth));
                formData.packageHeight.push(parseFloat(this.items[i].packageHeight));
                formData.value.push(parseFloat(this.items[i].value));

            }

            if (!formData.otherCharges) {
              formData.otherCharges = 0
            }

            if (this.$store.getters.user.choice == 0) {

                console.log("testdata", formData)

                if (!formData.orderDate) {
                    this.$refs.orderDate.$el.querySelector("input").focus();
                    return false;
                }

                if (!formData.sellerAddressId) {
                    this.$refs.warehouse.$el.focus()
                    return false;
                }

                if (!formData.modeType) {
                    this.$refs.mode.$el.focus()
                    return false;
                }

                formData.sellerAddressId = formData.sellerAddressId.sellerAddressId;
                formData.modeType = formData.modeType.value
                formData.orderType = "prepaid";
                formData.channel = 9
                formData.orderDate = new Date(formData.orderDate).toLocaleDateString(['zu-ZA'], {year: "numeric",month: "2-digit",day: "2-digit"});

                formData.packageWeight = formData.packageWeightArr.reduce((a, b) => {
                  return a + b;
                });

                let totalIdentical = 0;

                this.form.skuList.map(item => {
                  totalIdentical += item.identical ? parseFloat(item.identical) : 0
                })

                formData.identical = totalIdentical
                if (formData.consignorID && formData.consignorID != null) {
                  formData.consignorID = formData.consignorID.id
                } else {
                  if (formData.hasOwnProperty('consignorID')) {
                    delete formData.consignorID
                  }
                }
                

                try {

                    this.spinnerMPS = true

                    let result = await this.axios.post("/orders/mps", formData);

                    if (result.data.success == true && result.data.message) {
                      this.popToast("booked", "Success!", result.data.message);

                      setTimeout(() => { 
                        this.$router.push("/shipments")
                      }, 1000)

                    }
                    if (result.data.success == false && result.data.message) {
                        this.popToast("failed", "Failed!", result.data.message.response[0].error);
                    }
                    if (result.data.success == false && result.data.error) {
                        this.popToast("failed", "Failed!", result.data.error.response[0].error);
                    }
                    this.spinnerMPS = false
                }
                catch(error){
                    this.spinnerMPS = false
                }
            }
            else {

                if (!formData.orderDate) {
                  this.$refs.orderDate.$el.querySelector("input").focus();
                  return false;
                }

                if (!formData.sellerAddressId) {
                    this.$refs.warehouse.$el.focus()
                    return false;
                }

                formData.originCity = formData.sellerAddressId.originCity;
                formData.originPin = formData.sellerAddressId.originPin;
                formData.sellerAddressId = formData.sellerAddressId.sellerAddressId;
                formData.orderType = "prepaid";
                formData.orderDate = new Date(formData.orderDate).toLocaleDateString(['zu-ZA'], {year: "numeric",month: "2-digit",day: "2-digit"});

                formData.channel = 9

                formData.packageWeight = formData.packageWeightArr.reduce((a, b) => {
                  return a + b;
                });


                let totalIdentical = 0;

                this.form.skuList.map(item => {
                  totalIdentical += item.identical ? parseFloat(item.identical) : 0
                })

                formData.identical = totalIdentical
                if (formData.consignorID && formData.consignorID != null) {
                  formData.consignorID = formData.consignorID.id
                } else {
                  if (formData.hasOwnProperty('consignorID')) {
                    delete formData.consignorID
                  }
                }

                try {

                    this.spinnerMPS = true

                    let result = await this.axios.post("/choice/getchoicedata", formData);

                    formData.packageWeight = result.data.weight || 0;

                    this.formInfo = formData;

                    if (result.data && result.data.success == false) {
                        this.popToast("failed", "Failed!", result.data.error);
                    }

                    if (result.data.response && result.data.response[0].error) {
                        this.popToast("failed", "Failed!", result.data.response[0].error);
                    }

                    if (result.data && result.data.success == true) {
                        this.choiceData = result.data.choicepricing;
                        this.openPricingModal();
                    }

                    this.spinnerMPS = false

                }
                catch(error){
                    this.spinnerMPS = false
                    console.error(error);
                }

            }

        },
        goBack() {
            this.$router.go(-1);
        },

        addItem() {
          let newItem = {
            id: this.items.length + 1,
            quantity: this.items.quantity,
            weight: this.items.weight,
            width: this.items.width,
            height: this.items.height,
            length: this.items.length,
            value: this.items.value,
          };

          this.items.push(newItem);
        },
        
        removeItem(item) {
          const index = this.items.indexOf(item)
          this.items.splice(index, 1);
        },

    },
    watch: {
      'form.sellerAddressId': function(newValue) {
        if(newValue) {
          this.warehouseAddress = this.form.sellerAddressId.address
        }else {
          this.warehouseAddress = ''
        }
      }
    }
  };
</script>
<style lang="scss" scoped>
.dark-header.new-order tbody tr td{
    padding: 10px;
}
.cm {
  display: inline-block;
  font-size: 15px;
  font-weight: 600;
}
.search-results {
    position: absolute;
    margin: 0;
    padding: 0;
    top: 6.875rem;
    left: 0.80rem;
    width: calc(100% - 20.6rem);
    height: 13.1875rem;
    overflow-y: scroll;
    list-style-type: none;
    border-radius: 0.5rem;
    background-color: #fff;
    z-index: 1;
      .results {
        font-size: rem(10px);
        line-height: rem(12px);
        color: $gray-600;
        font-weight: 700;
      }
      li {
        cursor:pointer;
        font-size: rem(13px);
        line-height: rem(20px);
        border-top: 1px solid $gray-100;
        margin: 0 rem(5px);

        .result-item {
          position: relative;
          display: inline-flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          text-decoration: none;
          padding: rem(20px) 0 rem(21px) 0;
          .title {
            font-size: rem(13px);
            line-height: rem(20px);
            color: $gray-900;
            margin-right: rem(12px);
          }
          .status {
            font-size: rem(11px);
            line-height: rem(20px);
            color: $gray-600;
            margin-right: rem(12px);
          }
          .meta {
            font-size: rem(11px);
            line-height: rem(20px);
            color: $gray-600;
          }
          i {
            color: $gray-600;
            @include transition;
          }

          &:hover,
          &:focus,
          &:active {
            i {
              color: $blue;
            }
          }
          &:before {
            display: inline-flex;
            content: "";
            width: rem(4px);
            height: rem(4px);
            margin-left: rem(12px);
            margin-right: rem(8px);
            background-color: $blue;
            border-radius: 50%;
          }

          @include media-breakpoint-down(lg) {
            flex-direction: column;
            align-items: flex-start;
            padding: 1rem;
            i {
              position: absolute;
              top: calc(50% - 6px);
              right: rem(8px);
            }
            &:before {
              margin-left: 2px;
              margin-bottom: rem(12px);
            }
          }
        }

        .remove-query {
          color: $gray-600;
          display: inline;
          margin-left: auto;
          text-decoration: none;
          i {
            vertical-align: middle;
          }
          &:hover,
          &:focus,
          &:active {
            color: $red;
          }
        }
      }
    }
</style>