var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "new-order" },
    [
      _c("AppTopbar", {
        attrs: { title: "New Order", hideSearchNotif: "" },
        scopedSlots: _vm._u([
          {
            key: "left-button",
            fn: function() {
              return [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-sm add-icon mr-20",
                    attrs: { id: "add-icon-sign" },
                    on: { click: _vm.openAddFundsModal }
                  },
                  [
                    _c("i", { staticClass: "icon-add fs16 pl-0 pr-10" }),
                    _vm._v(_vm._s(_vm._f("currency")(_vm.due, "inr")))
                  ]
                ),
                _c(
                  "b-popover",
                  {
                    key: "hover",
                    staticStyle: { "z-index": "999", position: "absolute" },
                    attrs: {
                      target: "add-icon-sign",
                      placement: "bottom",
                      title: "Popover!",
                      "custom-class": "mw373 position-absolute z-99",
                      triggers: "hover"
                    }
                  },
                  [
                    _c("div", { staticClass: "text-gray-600" }, [
                      _c("div", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$store.getters.user.prepaid == 0
                                ? "Total Due"
                                : "Usable Balance"
                            ) +
                            " : " +
                            _vm._s(_vm._f("currency")(_vm.due, "inr")) +
                            " "
                        )
                      ]),
                      _c("div", [
                        _vm._v(" Click "),
                        _c("i", { staticClass: "icon-add fs12 pl-3 pr-3" }),
                        _vm._v(" to Add Funds ")
                      ])
                    ])
                  ]
                )
              ]
            },
            proxy: true
          },
          {
            key: "right-button",
            fn: function() {
              return [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "rounded", size: "sm" },
                    on: { click: _vm.goBack }
                  },
                  [_c("i", { staticClass: "icon-close-variant fs14" })]
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("div", { staticClass: "container-fluid pt-50 pt-md-80" }, [
        _c(
          "div",
          { staticClass: "row pt-14 pb-13 pb-md-14 pb-lg-20 pb-lg-24" },
          [
            _c("div", { staticClass: "col" }, [
              _c(
                "ul",
                { staticClass: "filter-list new-order" },
                _vm._l(_vm.tabs, function(tab) {
                  return _c(
                    "li",
                    {
                      key: tab.id,
                      class: [{ active: _vm.$store.state.tab === tab.id }]
                    },
                    [
                      _c(
                        "b-link",
                        {
                          staticClass: "btn-filter",
                          class: tab.title,
                          on: {
                            click: function($event) {
                              return _vm.$store.commit("setTab", tab.id)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(tab.title) + " ")]
                      )
                    ],
                    1
                  )
                }),
                0
              )
            ])
          ]
        )
      ]),
      _c(_vm.selectedTab.component, { tag: "component" }),
      _c("BillingAddFunds", { ref: "BillingAddFunds" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }