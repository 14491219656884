<template>
	<div>
		<b-modal
	        centered
	        v-model="isOpen"
	        title="International Order"
	        id="intlRedirect"
	        ref="intlRedirect"
	        modal-class="cancel-modal"
	        no-close-on-backdrop
	    >

	    <template v-slot:modal-header="{ close }">
	        <span class="fs18 lh28 fw500">
	   			International Order
	   		</span>
	    </template>

	    <template v-slot:modal-footer="{ close }">
			<div class="mb-30">
				<p>You will be redirected to Shypmax for international orders, you can use same login and password.</p>
			</div>
	        <div class="d-flex justify-content-between w-100">
	            <b-button
	                id="btnHide"
	                variant=" w-sm-100"
	                to="/"
	                class="m-0"
	            >
	                <span>Cancel</span>
	            </b-button>

	            <a
	                id="btnHide"
	                variant="primary w-sm-100"
	                href="https://app.shypmax.com/order#Single"
	                class="m-0 btn btn-primary"
					title="Redirect to Shypmax"
	            >
	                <span>Proceed</span>
	                <i class="icon-arrow fs13 ml-10"></i>
	            </a>
	        </div>

	    </template>

	  </b-modal>
	</div>
</template>

<script>
export default {
	name: "IntlShypmaxRedirect",
	data() {
		return {
			isOpen: true
		}
	}
}
</script>