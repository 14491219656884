var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.formSubmit($event)
        }
      }
    },
    [
      _c("div", { staticClass: "container-fluid pb-80 pb-md-0" }, [
        _c("div", { staticClass: "card card__new-order mb-16 mb-md-12" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-6" }, [
              _c("div", { staticClass: "card__content" }, [
                _c("div", { staticClass: "mx-auto" }, [
                  _c("span", { staticClass: "d-block fs18 lh28 fw500 mb-14" }, [
                    _vm._v(" Order Information ")
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col",
                        class: {
                          "col-md-6":
                            _vm.$store.state.global.showConsignor &&
                            _vm.$store.state.global.showConsignorrequest
                        }
                      },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "warehouse-field",
                              label: "Warehouse",
                              "label-for": "warehouse"
                            }
                          },
                          [
                            _c("multiselect", {
                              ref: "warehouse",
                              staticClass: "select-neworder",
                              attrs: {
                                id: "warehouse",
                                title: "Please select an item from the list",
                                options: _vm.singleOrderWarehouses,
                                label: "name",
                                searchable: true,
                                "show-labels": false,
                                required: ""
                              },
                              on: {
                                open: _vm.toggleLock,
                                close: _vm.toggleLock
                              },
                              model: {
                                value: _vm.form.sellerAddressId,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "sellerAddressId", $$v)
                                },
                                expression: "form.sellerAddressId"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.$store.state.global.showConsignor &&
                    _vm.$store.state.global.showConsignorrequest
                      ? _c(
                          "div",
                          { staticClass: "col-md-6 col" },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  id: "warehouse-field",
                                  label: "Consignor",
                                  "label-for": "consignor"
                                }
                              },
                              [
                                _c("multiselect", {
                                  ref: "warehouse",
                                  staticClass: "select-neworder",
                                  attrs: {
                                    id: "consignor",
                                    title:
                                      "Please select an item from the list",
                                    options: _vm.singleorderconsignors,
                                    label: "name",
                                    searchable: true,
                                    "show-labels": false
                                  },
                                  on: {
                                    open: _vm.toggleLock,
                                    close: _vm.toggleLock
                                  },
                                  model: {
                                    value: _vm.form.consignorID,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "consignorID", $$v)
                                    },
                                    expression: "form.consignorID"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "warehouse-address-field",
                              label: "Address",
                              "label-for": "warehouse-address"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "input-group-addon" },
                              [
                                _c("b-form-textarea", {
                                  attrs: {
                                    id: "warehouse-address",
                                    type: "text",
                                    maxlength: this.maxLength,
                                    disabled: "",
                                    rows: "3",
                                    "max-rows": "6"
                                  },
                                  model: {
                                    value: _vm.warehouseAddress,
                                    callback: function($$v) {
                                      _vm.warehouseAddress = $$v
                                    },
                                    expression: "warehouseAddress"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _c(
                          "b-form-group",
                          { attrs: { id: "orderId", "label-for": "order-id" } },
                          [
                            _c(
                              "label",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover.right",
                                    modifiers: { hover: true, right: true }
                                  }
                                ],
                                staticClass: "wsnw",
                                attrs: {
                                  for: "orderId",
                                  title:
                                    "Allowed special characters are # - / _"
                                }
                              },
                              [
                                _vm._v(" Order ID "),
                                _c("i", {
                                  staticClass:
                                    "icon-disclaimer fs12 text-gray-400"
                                })
                              ]
                            ),
                            _c("b-form-input", {
                              attrs: {
                                id: "order-id",
                                placeholder: "Enter order id",
                                minlength: "2",
                                maxlength: "35",
                                pattern: "[0-9a-z#A-Z_ /-]*",
                                title: "Allowed special characters are # - / _",
                                required: ""
                              },
                              model: {
                                value: _vm.form.orderId,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "orderId", $$v)
                                },
                                expression: "form.orderId"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "date-field",
                              label: "Date",
                              "label-for": "date"
                            }
                          },
                          [
                            _c(
                              "date-picker",
                              {
                                ref: "orderDate",
                                attrs: {
                                  id: "new-order-daterange",
                                  placeholder: "yyyy-mm-dd",
                                  type: "date",
                                  "disabled-date": function(date) {
                                    return date > new Date()
                                  },
                                  "prefix-class": "xmx",
                                  required: ""
                                },
                                model: {
                                  value: _vm.form.orderDate,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "orderDate", $$v)
                                  },
                                  expression: "form.orderDate"
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "icon-calendar",
                                  attrs: { slot: "icon-calendar" },
                                  slot: "icon-calendar"
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "type-field",
                              label: "Order Type",
                              "label-for": "type"
                            }
                          },
                          [
                            _c("multiselect", {
                              ref: "orderType",
                              staticClass: "select-neworder",
                              attrs: {
                                id: "type",
                                title: "Please select an item from the list",
                                options: _vm.types,
                                searchable: false,
                                "show-labels": false,
                                required: ""
                              },
                              on: {
                                open: _vm.toggleLock,
                                close: _vm.toggleLock
                              },
                              model: {
                                value: _vm.form.orderType,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "orderType", $$v)
                                },
                                expression: "form.orderType"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "total-value-field",
                              label: "Total Value",
                              "label-for": "total-value"
                            }
                          },
                          [
                            _c(
                              "b-input-group",
                              { attrs: { append: "₹" } },
                              [
                                _c("b-input", {
                                  attrs: {
                                    id: "total-value",
                                    title:
                                      "Only numeric or decimal values allowed",
                                    type: "number",
                                    pattern: "((d+)((.d{1,2})?))$",
                                    step: ".01",
                                    placeholder: "0.00",
                                    min: "0.1",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.form.totalValue,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "totalValue", $$v)
                                    },
                                    expression: "form.totalValue"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.$store.getters.user.choice == 0
                      ? _c(
                          "div",
                          { staticClass: "col-md-6" },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  id: "mode-field",
                                  label: "Mode Type",
                                  "label-for": "mode"
                                }
                              },
                              [
                                _c("multiselect", {
                                  ref: "modeType",
                                  staticClass: "select-neworder",
                                  attrs: {
                                    id: "mode",
                                    title:
                                      "Please select an item from the list",
                                    label: "name",
                                    options: _vm.$store.getters.mode.domestic,
                                    searchable: false,
                                    "show-labels": false,
                                    required: ""
                                  },
                                  on: {
                                    open: _vm.toggleLock,
                                    close: _vm.toggleLock
                                  },
                                  model: {
                                    value: _vm.form.modeType,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "modeType", $$v)
                                    },
                                    expression: "form.modeType"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.form.totalValue >= 50000
                      ? _c(
                          "div",
                          { staticClass: "col-6" },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "wsnw",
                                attrs: {
                                  id: "length-field",
                                  label: "E-WayBill",
                                  "label-for": "ewaybill"
                                }
                              },
                              [
                                _c("b-input", {
                                  attrs: {
                                    id: "ewaybill",
                                    type: "text",
                                    placeholder: "E-WayBill",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.form.ewaybill,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "ewaybill", $$v)
                                    },
                                    expression: "form.ewaybill"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ])
              ])
            ]),
            _c("div", { staticClass: "col-lg-6" }, [
              _c("div", { staticClass: "card__content" }, [
                _c("div", { staticClass: "mx-auto" }, [
                  _c("span", { staticClass: "d-block fs18 lh28 fw500 mb-14" }, [
                    _vm._v(" Customer Information ")
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "customer-name-field",
                              label: "Name",
                              "label-for": "customer-name"
                            }
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "customer-name",
                                type: "text",
                                maxlength: "35",
                                placeholder: "Enter customer name",
                                required: ""
                              },
                              on: {
                                keyup: function($event) {
                                  return _vm.getCustomers(_vm.form.customerName)
                                }
                              },
                              model: {
                                value: _vm.form.customerName,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "customerName", $$v)
                                },
                                expression: "form.customerName"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.customerList && _vm.customerList.length
                      ? _c("div", { staticClass: "results-wrap" }, [
                          _c(
                            "ul",
                            {
                              staticClass: "search-results",
                              style:
                                _vm.customerList && _vm.customerList.length == 1
                                  ? "height: max-content !important"
                                  : ""
                            },
                            _vm._l(_vm.customerList, function(item) {
                              return _c(
                                "li",
                                { key: item.id, staticClass: "p-5 d-flex" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "w-100 col d-flex",
                                      on: {
                                        click: function($event) {
                                          return _vm.fillCustomerfield(item.id)
                                        }
                                      }
                                    },
                                    [
                                      _c("div", [
                                        _c("p", [
                                          _c("span", [
                                            _c("strong", [_vm._v("Name")]),
                                            _vm._v(" - ")
                                          ]),
                                          _vm._v(_vm._s(item.name))
                                        ]),
                                        _c("p", [
                                          _c("span", [
                                            _c("strong", [_vm._v("Pincode")]),
                                            _vm._v(" - ")
                                          ]),
                                          _vm._v(_vm._s(item.pincode))
                                        ]),
                                        _c("p", [
                                          _c("span", [
                                            _c("strong", [_vm._v("Contact")]),
                                            _vm._v(" - ")
                                          ]),
                                          _vm._v(_vm._s(item.contact))
                                        ])
                                      ])
                                    ]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              label: "Email (Optional)",
                              "label-for": "customer-email"
                            }
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "customer-email",
                                placeholder: "Customer Email",
                                pattern:
                                  "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$",
                                title: "Only valid email id is allowed.",
                                type: "email"
                              },
                              model: {
                                value: _vm.form.email,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "email", $$v)
                                },
                                expression: "form.email"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "customer-address-field",
                              label: "Address",
                              "label-for": "customer-address",
                              description:
                                "Characters: " +
                                _vm.form.customerAddress.length +
                                " / " +
                                this.maxLength
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "input-group-addon" },
                              [
                                _c("b-form-textarea", {
                                  attrs: {
                                    id: "customer-address",
                                    type: "text",
                                    placeholder: "Enter customer address",
                                    rows: "3",
                                    "max-rows": "6",
                                    maxlength: "140",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.form.customerAddress,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "customerAddress", $$v)
                                    },
                                    expression: "form.customerAddress"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "city-field",
                              label: "City",
                              "label-for": "city"
                            }
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "city",
                                type: "text",
                                placeholder: "Enter city",
                                maxlength: "35",
                                required: ""
                              },
                              model: {
                                value: _vm.form.customerCity,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "customerCity", $$v)
                                },
                                expression: "form.customerCity"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-md-6" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "pin-field",
                              label: "Pin Code",
                              "label-for": "pin"
                            }
                          },
                          [
                            _c("b-form-input", {
                              attrs: {
                                id: "pin",
                                placeholder: "110001",
                                type: "text",
                                maxlength: "6",
                                minlength: "6",
                                oninvalid:
                                  "this.setCustomValidity('Enter valid pincode.')",
                                oninput: "this.setCustomValidity('')",
                                formatter: _vm.numbers,
                                required: ""
                              },
                              model: {
                                value: _vm.form.customerPinCode,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "customerPinCode", $$v)
                                },
                                expression: "form.customerPinCode"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 col-md-6" },
                      [
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "phone-field",
                              label: "Mobile number",
                              "label-for": "phone-number"
                            }
                          },
                          [
                            _c(
                              "b-input-group",
                              [
                                _c("b-form-input", {
                                  attrs: {
                                    id: "phone-number",
                                    type: "text",
                                    maxlength: "10",
                                    minlength: "10",
                                    formatter: _vm.numbers,
                                    placeholder: "8888888888",
                                    oninvalid:
                                      "this.setCustomValidity('Enter valid mobile number.')",
                                    oninput: "this.setCustomValidity('')",
                                    required: ""
                                  },
                                  model: {
                                    value: _vm.form.customerContact,
                                    callback: function($$v) {
                                      _vm.$set(_vm.form, "customerContact", $$v)
                                    },
                                    expression: "form.customerContact"
                                  }
                                }),
                                _c(
                                  "div",
                                  { staticClass: "input-group-append" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group-text" },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "icon-phone fs16 text-gray-600"
                                        })
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ])
              ])
            ])
          ]),
          _c("div", { staticClass: "border-top w-100 mt-16 mb-16" }),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "span",
                { staticClass: "d-block fs18 lh28 fw500 mb-5 pl-14" },
                [_vm._v(" Item Information ")]
              ),
              _vm.user.invoicing &&
              _vm.showGstInvoice &&
              _vm.form.orderType != "REVERSE"
                ? _c(
                    "span",
                    { staticClass: "d-block lh28 fw500 pl-14" },
                    [
                      _vm._v(
                        " Do you want GST Compliant invoice along with label? "
                      ),
                      _c("b-form-checkbox", {
                        staticClass: "d-inline mr-20 ml-20 h2",
                        attrs: {
                          "unchecked-value": "0",
                          value: "1",
                          switch: ""
                        },
                        model: {
                          value: _vm.form.isTaxInvoice,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "isTaxInvoice", $$v)
                          },
                          expression: "form.isTaxInvoice"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "ul",
                { staticClass: "package-items mt-30" },
                _vm._l(_vm.form.skuList, function(item, index) {
                  return _c("li", { key: index, staticClass: "pt-0 pb-0" }, [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-3 col-12" }, [
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            {
                              staticClass: "col-1 col-md-1 d-flex",
                              class: { "align-items-center": index < 1 }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "fs16 lh19 fw500 mt-16" },
                                [_vm._v(" #" + _vm._s(index + 1) + " ")]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "col-11 col-md-11" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: index > 0 ? "" : "Item Name*"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      placeholder: "Enter item name",
                                      type: "text",
                                      maxlength: "100",
                                      title: "Item Name",
                                      required: ""
                                    },
                                    model: {
                                      value: item.itemName,
                                      callback: function($$v) {
                                        _vm.$set(item, "itemName", $$v)
                                      },
                                      expression: "item.itemName"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "col-md-2 col-12" },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: index > 0 ? "" : "SKU Name" } },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  placeholder: "SKU Name",
                                  type: "text",
                                  maxlength: "35",
                                  title: "SKU Name"
                                },
                                model: {
                                  value: item.sku,
                                  callback: function($$v) {
                                    _vm.$set(item, "sku", $$v)
                                  },
                                  expression: "item.sku"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: " col-6",
                          class:
                            _vm.form.isTaxInvoice == "1"
                              ? "col-md-1"
                              : "col-md-2"
                        },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: index > 0 ? "" : "Quantity*" } },
                            [
                              _c("b-input", {
                                staticClass: "new-order-number",
                                attrs: {
                                  min: "1",
                                  type: "number",
                                  title: "Only numeric value allowed.",
                                  placeholder: "0",
                                  required: ""
                                },
                                model: {
                                  value: item.quantity,
                                  callback: function($$v) {
                                    _vm.$set(item, "quantity", $$v)
                                  },
                                  expression: "item.quantity"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: " col-6",
                          class:
                            _vm.form.isTaxInvoice == "1"
                              ? "col-md-1"
                              : "col-md-2"
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: { label: index > 0 ? "" : "Weight (kg)" }
                            },
                            [
                              _c("b-input", {
                                attrs: {
                                  type: "number",
                                  title: "Only numeric value allowed.",
                                  placeholder: "0.00",
                                  step: ".01",
                                  min: _vm.$store.state.minW,
                                  max: "100"
                                },
                                on: { input: _vm.totalWeight },
                                model: {
                                  value: item.weight,
                                  callback: function($$v) {
                                    _vm.$set(item, "weight", $$v)
                                  },
                                  expression: "item.weight"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: " col-6",
                          class:
                            _vm.form.isTaxInvoice == "1"
                              ? "col-md-1"
                              : "col-md-2"
                        },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: index > 0 ? "" : "Value" } },
                            [
                              _c(
                                "b-input-group",
                                { attrs: { append: "₹" } },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      type: "number",
                                      placeholder:
                                        _vm.form.isTaxInvoice == "1"
                                          ? "0.0"
                                          : "0",
                                      title: "Only numeric value allowed.",
                                      pattern: "((d+)((.d{1,2})?))$",
                                      step:
                                        _vm.form.isTaxInvoice == "1" ? 0.1 : 0,
                                      required:
                                        _vm.form.isTaxInvoice == "1"
                                          ? true
                                          : false,
                                      min:
                                        _vm.form.isTaxInvoice == "1" ? 0.1 : 0
                                    },
                                    model: {
                                      value: item.price,
                                      callback: function($$v) {
                                        _vm.$set(item, "price", $$v)
                                      },
                                      expression: "item.price"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      (_vm.form.isTaxInvoice == "1"
                      ? true
                      : false)
                        ? _c(
                            "div",
                            { staticClass: "col-md-1 col-12" },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: index > 0 ? "" : "HSN" } },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      placeholder: "HSN",
                                      type: "text",
                                      minlength: "3",
                                      maxlength: "6",
                                      title: "HSN Number"
                                    },
                                    model: {
                                      value: item.hsn,
                                      callback: function($$v) {
                                        _vm.$set(item, "hsn", $$v)
                                      },
                                      expression: "item.hsn"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      (_vm.form.isTaxInvoice == "1"
                      ? true
                      : false)
                        ? _c(
                            "div",
                            { staticClass: "col-md-1 col-12" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: { label: index > 0 ? "" : "Discount" }
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    { attrs: { append: "₹" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          placeholder: "0",
                                          type: "number",
                                          min: "0",
                                          step: "0.01",
                                          title: "Discount"
                                        },
                                        model: {
                                          value: item.discount,
                                          callback: function($$v) {
                                            _vm.$set(item, "discount", $$v)
                                          },
                                          expression: "item.discount"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      (_vm.form.isTaxInvoice == "1"
                      ? true
                      : false)
                        ? _c(
                            "div",
                            {
                              staticClass: "col-md-2 col-12",
                              staticStyle: { "max-width": "12% !important" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-md-10" },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: { label: index > 0 ? "" : "Tax %" }
                                    },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          placeholder: "0.00",
                                          type: "number",
                                          max: "100",
                                          min: "0",
                                          step: "0.01",
                                          title: "Tax %"
                                        },
                                        model: {
                                          value: item.taxRate,
                                          callback: function($$v) {
                                            _vm.$set(item, "taxRate", $$v)
                                          },
                                          expression: "item.taxRate"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          index > 0
                            ? _c(
                                "b-link",
                                {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover.right",
                                      modifiers: { hover: true, right: true }
                                    }
                                  ],
                                  staticClass: "btn-trash mt-16",
                                  attrs: { title: "Delete item" },
                                  on: {
                                    click: function($event) {
                                      _vm.form.skuList.splice(index, 1)
                                      _vm.totalWeight()
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "icon-trash" })]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ])
                  ])
                }),
                0
              )
            ]),
            _c("div", { staticClass: "mx-auto text-left" })
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "mt-20 mb-48 ml-0 ml-md-14 w-sm-100",
                    attrs: { variant: "tertiary" },
                    on: {
                      click: function($event) {
                        _vm.form.skuList.push({
                          itemHeight: 0,
                          itemLength: 0,
                          itemWidth: 0,
                          itemWeight: 0
                        })
                        _vm.totalWeight()
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "icon-add fs16 mr-10" }),
                    _vm._v(" Add Item ")
                  ]
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col" }, [
              _c("div", { staticClass: "border-top pb-10 pl-14" }, [
                _c("div", { staticClass: "row mt-20" }, [
                  _c("div", { staticClass: "col-md-7 col-12" }, [
                    _c(
                      "span",
                      { staticClass: "d-block fs18 lh28 fw500 mb-14" },
                      [_vm._v(" Package Dimension ")]
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-3 col-6" },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "wsnw",
                              attrs: {
                                id: "length-field",
                                label: "Length (cm)",
                                "label-for": "length"
                              }
                            },
                            [
                              _c("b-input", {
                                attrs: {
                                  id: "length",
                                  type: "number",
                                  max: "999",
                                  min: "1",
                                  placeholder: "0",
                                  required: ""
                                },
                                model: {
                                  value: _vm.form.package.itemLength,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form.package,
                                      "itemLength",
                                      $$v
                                    )
                                  },
                                  expression: "form.package.itemLength"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-3 col-6" },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "wsnw",
                              attrs: {
                                id: "width-field",
                                label: "Width (cm)",
                                "label-for": "width"
                              }
                            },
                            [
                              _c("b-input", {
                                attrs: {
                                  id: "width",
                                  type: "number",
                                  max: "999",
                                  min: "1",
                                  placeholder: "0",
                                  required: ""
                                },
                                model: {
                                  value: _vm.form.package.itemWidth,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form.package, "itemWidth", $$v)
                                  },
                                  expression: "form.package.itemWidth"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-md-3 col-6" },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "wsnw",
                              attrs: {
                                id: "height-field",
                                label: "Height (cm)",
                                "label-for": "height"
                              }
                            },
                            [
                              _c("b-input", {
                                attrs: {
                                  id: "height",
                                  type: "number",
                                  max: "999",
                                  min: "1",
                                  placeholder: "0",
                                  required: ""
                                },
                                model: {
                                  value: _vm.form.package.itemHeight,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form.package,
                                      "itemHeight",
                                      $$v
                                    )
                                  },
                                  expression: "form.package.itemHeight"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "col-md-5 col-12" }, [
                    _c(
                      "span",
                      { staticClass: "d-block fs18 lh28 fw500 mb-14" },
                      [_vm._v(" Package Weight ")]
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-md-5 col-12" },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "wsnw",
                              attrs: {
                                id: "length-field",
                                label: "Total Weight (kg)",
                                "label-for": "length"
                              }
                            },
                            [
                              _c("b-input", {
                                attrs: {
                                  id: "totalWeight",
                                  type: "number",
                                  placeholder: "0.00",
                                  step: ".01",
                                  min: _vm.$store.state.minW,
                                  max: _vm.$store.state.maxW,
                                  disabled: _vm.totalWeights ? true : false,
                                  required: _vm.totalWeights ? false : true
                                },
                                model: {
                                  value: _vm.form.packageWeight,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "packageWeight", $$v)
                                  },
                                  expression: "form.packageWeight"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ])
                ])
              ])
            ])
          ]),
          (_vm.form.isTaxInvoice == "1"
          ? true
          : false)
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("div", { staticClass: "border-top pb-10 pl-14" }, [
                    _c("div", { staticClass: "row mt-20" }, [
                      _c("div", { staticClass: "col-md-7 col-12" }, [
                        _c(
                          "span",
                          { staticClass: "d-block fs18 lh28 fw500 mb-14" },
                          [_vm._v(" Additional Information ")]
                        ),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md-3 col-6" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "wsnw",
                                  attrs: {
                                    id: "invoiceNumber-field",
                                    label: "Invoice Number",
                                    "label-for": "invoiceNumber"
                                  }
                                },
                                [
                                  _c("b-input", {
                                    attrs: {
                                      id: "invoiceNumber",
                                      placeholder: "Invoice number",
                                      type: "text",
                                      title: "Invoice number",
                                      required: "",
                                      maxlength: "15",
                                      minlength: "3"
                                    },
                                    model: {
                                      value: _vm.form.invoiceNumber,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form, "invoiceNumber", $$v)
                                      },
                                      expression: "form.invoiceNumber"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-3 col-6" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "invoiceDate-field",
                                    label: "Invoice Date",
                                    "label-for": "invoiceDate"
                                  }
                                },
                                [
                                  _c(
                                    "date-picker",
                                    {
                                      ref: "invoiceDate",
                                      attrs: {
                                        id: "invoiceDate",
                                        placeholder: "yyyy-mm-dd",
                                        type: "date",
                                        "prefix-class": "xmx",
                                        title: "Invoice date",
                                        required: ""
                                      },
                                      model: {
                                        value: _vm.form.invoiceDate,
                                        callback: function($$v) {
                                          _vm.$set(_vm.form, "invoiceDate", $$v)
                                        },
                                        expression: "form.invoiceDate"
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "icon-calendar",
                                        attrs: { slot: "icon-calendar" },
                                        slot: "icon-calendar"
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-md-3 col-6" },
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "wsnw",
                                  attrs: {
                                    id: "otherCharges-field",
                                    label: "Shipping / Other Charges",
                                    "label-for": "otherCharges"
                                  }
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    { attrs: { append: "₹" } },
                                    [
                                      _c("b-input", {
                                        attrs: {
                                          id: "otherCharges",
                                          placeholder: "0.00",
                                          title: "Shipping / Other charges",
                                          type: "number",
                                          min: "0",
                                          step: "0.01"
                                        },
                                        model: {
                                          value: _vm.form.otherCharges,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "otherCharges",
                                              $$v
                                            )
                                          },
                                          expression: "form.otherCharges"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "d-flex my-16 mt-md-24 pl-14" },
            [
              _vm.$store.getters.user.choice == 1
                ? _c(
                    "b-button",
                    {
                      staticClass: "w-sm-100",
                      attrs: { type: "submit", variant: "primary", size: "lg" }
                    },
                    [_vm._v(" Create Order ")]
                  )
                : _vm._e(),
              _vm.$store.getters.user.choice == 0
                ? _c(
                    "b-button",
                    {
                      staticClass: "w-sm-100",
                      attrs: { type: "submit", variant: "primary", size: "lg" }
                    },
                    [
                      _vm._v(" Book Order "),
                      _vm.submitDisable
                        ? _c("b-spinner", { staticClass: "ml-10" })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c("choiceDialog", {
                ref: "choiceDialog",
                attrs: {
                  formInfo: _vm.oldFormInfo,
                  choiceData: _vm.choiceData
                },
                on: { openModal: _vm.openPricingModal }
              }),
              _c("reminderModal", {
                ref: "reminderModal",
                attrs: { reminderData: _vm.reminderData },
                on: {
                  "update:reminderData": function($event) {
                    _vm.reminderData = $event
                  },
                  "update:reminder-data": function($event) {
                    _vm.reminderData = $event
                  },
                  openModal: _vm.openreminder
                }
              })
            ],
            1
          ),
          _vm.user.invoicing &&
          _vm.showGstInvoice &&
          _vm.form.orderType != "REVERSE"
            ? _c(
                "p",
                { staticClass: "ml-14" },
                [
                  _c("b", [_vm._v("Note:")]),
                  _vm._v(" For customizing your labels and invoice "),
                  _c("b-link", { attrs: { to: "/settings#Customization" } }, [
                    _vm._v("Click here")
                  ])
                ],
                1
              )
            : _vm._e()
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }