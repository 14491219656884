var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { id: "infoModal", centered: "", "hide-footer": "", size: "lg" },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function(ref) {
            var close = ref.close
            return [
              _c(
                "div",
                { staticClass: " text-center", staticStyle: { width: "100%" } },
                [
                  _c(
                    "span",
                    {
                      staticClass: "d-block mt-auto float-left fs24 lh28 fw500"
                    },
                    [_vm._v(" Reminder - Monthly Recharge ")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "float-right",
                      attrs: { size: "sm", variant: "rounded" },
                      on: {
                        click: function($event) {
                          return close()
                        }
                      }
                    },
                    [_c("i", { staticClass: "icon-close-variant" })]
                  )
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.isOpen,
        callback: function($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen"
      }
    },
    [
      _c("p", { staticClass: "mt-10 fs16" }, [
        _vm._v(
          "Please recharge with " +
            _vm._s(_vm._f("currency")(_vm.modalData.amountToRecharge, "inr")) +
            " in " +
            _vm._s(_vm.modalData.noOfDaysRemaining) +
            " day" +
            _vm._s(_vm.modalData.noOfDaysRemaining > 1 ? "s" : "") +
            " to continue with your current plan or it will be automatically downgraded to the best suited plan. "
        )
      ]),
      _c("div", { staticClass: "d-flex mt-20 justify-content-evenly" }, [
        _c(
          "div",
          {
            staticClass: "col-md-5 box mt-10",
            staticStyle: { background: "#e5f0ff" }
          },
          [
            _c("p", { staticClass: "fs16 fw500" }, [_vm._v("Current Plan")]),
            _c("span", { staticClass: "plan" }, [
              _vm._v(_vm._s(_vm.modalData.currentPlan))
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "col-md-5 box mt-10 ",
            staticStyle: { background: "#e5f0ff" }
          },
          [
            _c("p", { staticClass: "fs16 fw500" }, [
              _vm._v("Next Renewal Date")
            ]),
            _c("span", { staticClass: "plan" }, [
              _vm._v(_vm._s(_vm.modalData.nextRenewDate))
            ])
          ]
        )
      ]),
      _c("div", { staticClass: "d-flex mt-10 justify-content-evenly" }, [
        _c(
          "div",
          {
            staticClass: "col-md-5 box mt-10",
            staticStyle: { background: "#e5f0ff" }
          },
          [
            _c("p", { staticClass: "fs16 fw500" }, [
              _vm._v(
                "Recharge since " + _vm._s(_vm.modalData.lastDateOfRecharge)
              )
            ]),
            _c("span", { staticClass: "plan" }, [
              _vm._v(
                _vm._s(
                  _vm._f("currency")(_vm.modalData.totalRechargeDone, "inr")
                )
              )
            ])
          ]
        ),
        _c(
          "div",
          {
            staticClass: "col-md-5 box mt-10",
            staticStyle: { background: "#e5f0ff" }
          },
          [
            _c("p", { staticClass: "fs16 fw500" }, [
              _vm._v("Minimum Monthly Recharge")
            ]),
            _c("span", { staticClass: "plan" }, [
              _vm._v(
                _vm._s(
                  _vm._f("currency")(
                    _vm.modalData.monthlyMinimumRecharge,
                    "inr"
                  )
                )
              )
            ])
          ]
        )
      ]),
      _c(
        "div",
        { staticClass: "pt-60 pt-md-60 text-center" },
        [
          _c(
            "b-button",
            {
              attrs: { size: "lg" },
              on: {
                click: function($event) {
                  return _vm.navigate("pricecalculator")
                }
              }
            },
            [_vm._v(" View Details ")]
          ),
          _c("span", { staticClass: "mx-10" }),
          _c(
            "b-button",
            {
              attrs: { variant: "primary", size: "lg" },
              on: { click: _vm.openAddfunds }
            },
            [
              _vm._v(
                " Make Payment of " +
                  _vm._s(
                    _vm._f("currency")(_vm.modalData.amountToRecharge, "inr")
                  ) +
                  " "
              )
            ]
          )
        ],
        1
      ),
      _c("BillingAddFunds", { ref: "BillingAddFunds" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }